.map-container {
  $map-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  $button-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  $map-radius: 2px;

  $offset-top: 10px;
  $offset-top-xs: 5px;
  $offset-bottom: 14px;
  $offset-left: 10px;
  $offset-right: 10px;
  $sidepane-content-width: 295px;
  $sidepane-btn-width: 65px;
  $sidepane-width: $sidepane-btn-width + $sidepane-content-width;

  display: flex;
  position: relative;
  width: 100vw;
  flex: 1 0;

  #oskari-map {
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  //
  // Side panel
  //
  .map-sidepanel-container {

    height: calc(100% - 20px);
    top: $offset-top;
    left: $offset-left;
    position: absolute;
    overflow-y: auto;
    box-shadow: $map-shadow;
    scrollbar-width: none;
    background-color: #fff;
    max-width: $sidepane-width;
    border-radius: $map-radius;
    transition: 0.4s transform ease-in-out;

    @media (max-width: $breakpoint-3-1) {
      top: $offset-top-xs;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &.open {
      transform: translateX(0px);
    }
    &.close {
      transform: translateX(0px - $sidepane-width - $offset-left);
    }

    & > .row {
      flex-wrap: nowrap;
      min-height: 100%;
    }

    .row.hidden {
      display: none;
    }

    .sidepanel-content {
      max-width: $sidepane-content-width;
    }

    .sidepanel-buttons {
      background-color: rgba(42, 110, 187, 0.09);
      max-width: $sidepane-btn-width;

      .map-sidepanel-button {
        display: block;
        position: sticky;
        top: 0;
        text-align: center;
        border-bottom: 2px solid #fff;
        text-decoration: none;

        height: $sidepane-btn-width + 10px;
        width: $sidepane-btn-width;

        font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial;
        @include rem(line-height, 15px);
        @include rem(font-size, 12px);
        text-transform: uppercase;
        color: $lake;

        background: transparent;
        border-radius: 0;
        padding: 16px 0 0;

        &:nth-of-type(2) {
          top: $sidepane-btn-width + 10px;
        }
        &:nth-of-type(3) {
          top: 2 * ($sidepane-btn-width + 10px);
        }

        svg {
          height: auto;
          max-height: 24px;
          width: 100%;
          color: inherit;
          margin-right: 0;
        }

        &.selected {
          border-left: 3px solid $color-persimmon;
          background-color: #fff;
          border-bottom: none;

          svg {
            filter: grayscale(100%) contrast(999%);
            transform: translateX(-3px);
          }
          span:last-of-type {
            transform: translateX(-3px);
            color: #000;
          }
        }
        span {
          width: $sidepane-btn-width;
        }
      }
    }
  }

  .sidepanel-toggle {
    transition: 0.4s transform ease-in-out;
    border-radius: $map-radius;
    box-shadow: $button-shadow;
    background: #fff;
    left: $offset-left;
    top: $offset-top;
    position: absolute;
    cursor: pointer;
    display: block;
    outline: none;
    height: 50px;
    width: 30px;
    padding: 0;
    z-index: 0;
    margin: 0;

    svg {
      color: $lake;
      transition: 0.4s transform ease-in-out;
      transform: rotate(180deg);
      width: 100%;
      height: auto;
    }

    &.open {
      transform: translateX($sidepane-width);
    }
    &.close {
      transform: translateX(0px);
      svg {
        transform: rotate(0deg);
      }
    }
    &:hover {
      background: #fff;
    }
    &:focus {
      box-shadow: $button-shadow;
    }
  }

  .show-list-button {
    background: transparent;
    display: inline-block;
    position: absolute;
    top: $offset-top;
    left: 10px;
    z-index: 10;
  }

  //
  // Mobile side panel
  //
  .map-sidepanel-container-mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto;
    z-index: 20;
    transition: 0.4s transform ease-in-out;
    margin-bottom: -50px;
    background: #fff;
    width: 100vw;

    &.open {
      transform: translateX(0px);
    }
    &.close {
      transform: translateX(calc(-100vw - 15px));
    }
  }

  //
  // Mobile tabs
  //
  .floating-buttons {
    transform: translate(-50%, -50%);
    min-width: 240px;
    bottom: -8px;
    left: 50%;

    background: transparent;
    display: inline-block;
    position: fixed;
    z-index: 30;

    .map-sidepanel-button {
      @include rem(font-size, 12px);
      display: inline-block;
      box-shadow: $button-shadow;
      background-color: #fff;
      line-height: 1.3;
      color: $lake;
      padding: 10px 20px;

      border-radius: 28px;
      overflow: hidden;
      max-width: 80px;
      height: 56px;
      text-align: center;

      svg {
        max-height: 24px;
        color: inherit;
        width: 100%;
        height: auto;
      }

      &.selected {
        background-color: $lake;
        color: #fff;
        svg {
          color: inherit;
        }
      }
    }

    .map-sidepanel-button:not(:first-child):not(:last-child), .map-sidepanel-button:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    .map-sidepanel-button:first-child, .map-sidepanel-button:first-child {
      padding: 8px 17px 10px 26px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .map-sidepanel-button:last-child, .map-sidepanel-button:last-child {
      padding: 8px 26px 10px 17px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  //
  // Map control buttons
  //
  .map-button-container {
    background-color: rgba(0, 0, 0, 0.35);
    display: inline-block;
    border-radius: $map-radius * 2;
    position: absolute;
    padding: 4px;

    &.top {
      top: $offset-top;
    }
    &.right {
      right: $offset-right;
    }
    &.bottom {
      bottom: $offset-bottom;
    }

    @media (max-width: $breakpoint-3-1) {
      &.top {
        top: $offset-top-xs;
      }
      background-color: rgba(0, 0, 0, 0);
      max-width: 48px;
    }

    button.map-button {
      background-color: #fff;
      box-shadow: $button-shadow;
      border-radius: $map-radius;
      color: $lake;
    }

    button.zoom-in, button.zoom-out, button.rounded-circle {
      position: relative; // for z-index
      height: 30px;
      width: 30px;
      padding: 0;
      z-index: 4;
      svg {
        margin-top: 2px;
        height: 14px;
        width: 14px;
        color: $lake;
      }

      @media (max-width: $breakpoint-3-1) {
        &:not(:first-of-type) {
          margin-top: 10px;
        }
        float: right;
        height: 40px;
        width: 40px;
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    //
    // Zoom slider styles
    //
    $track-w: 144px;
    $track-h: 2px;
    $thumb-w: 20px;
    $thumb-h: 8px;

    @mixin track() {
      box-sizing: border-box;
      box-shadow: $button-shadow;
      border: none;
      width: $track-w;
      height: $track-h;
      background: #fff;
      padding: 0 4px;
      z-index: 2;
    }

    @mixin thumb() {
      box-sizing: border-box;
      border-radius: 4px;
      border: solid 3px #fff;
      box-shadow: $button-shadow;
      background-color: $lake;
      box-sizing: border-box;
      height: $thumb-w;
      width: $thumb-h;
      z-index: 3;
    }

    .slider-container {
      height: $track-w;
    }

    [type='range'] {
      &, &::-webkit-slider-thumb {
        -webkit-appearance: none;
      }

      top: 50%;
      left: 50%;
      margin: 0;
      padding: 0;
      outline: none;
      width: $track-w;
      height: $thumb-w;
      position: absolute;
      background: transparent;
      transform: translate(-50%, -50%) rotate(-90deg);

      // Apparently there still (in 2023) is no standard way to style a range input.
      &::-webkit-slider-runnable-track {
        @include track
      }
      &::-moz-range-track {
        @include track
      }
      &::-webkit-slider-thumb {
        margin-top: .5*($track-h - $thumb-w);
        @include thumb
      }
      &::-moz-range-thumb {
        @include thumb
      }
    }

    //
    // Map background layer selector
    //
    .map-layer-menu {
      transition: 0.3s max-width ease-in-out;
      overflow: hidden;
      display: flex;
      height: 50px;
      float: right;
    }

    .map-layer-menu:not(.visible) {
      min-width: 30px;
      max-width: 133px;
    }

    .map-layer-menu.visible {
      max-width: 339px;
    }

    button.map-layer-button {
      transition: 0.3s transform ease-in-out;
      border-radius: $map-radius;
      background-color: $lake;
      box-shadow: none;
      min-width: 30px;
      height: 50px;
      padding: 0;
      margin: 0;

      span.icon {
        padding-top: 2px;
      }
      svg {
        transition: 0.3s transform ease-in-out;
        transform: rotate(180deg);
        transform-origin: center;
        height: auto;
        max-height: 24px;
        width: 100%;
        max-width: 24px;
        color: #fff;
      }
    }

    button.map-layer-button:hover {
      background-color: lighten($lake, 10%);
    }

    .map-layer-menu.visible button.map-layer-button svg {
      transform: rotate(0deg);
    }

    .map-layer {
      border: solid 1px $gray-k20;
      border-radius: $map-radius;
      margin: 0 0 0 3px;
      min-width: 100px;
      max-height: 50px;
      overflow: hidden;
    }

    .map-layer button {
      background: url(#{$image_base_path}/img/map-layer-sprite.jpg) no-repeat;
      transition: 0.2s transform ease-in-out;
      background-position: -98px 0px;
      box-sizing: border-box;
      background-size: cover;
      padding: 0;

      span {
        transition: 0.2s opacity ease-in-out;
        background-color: #fff;
        overflow: hidden;
        text-align: left;
        margin-top: 30px;
        font-size: 12px;
        padding: 0 4px;
        display: block;
        color: #000;
        opacity: 0.8;
        height: 18px;
        width: 100px;
      }
    }

    .map-layer button.layer-382 {
      background-position: -196px 0px;
    }
    .map-layer button.layer-3 {
      background-position: 0px 0px;
    }

    .map-layer:hover button {
      transform: scale(1.2);
      span {
        opacity: 0;
      }
    }

    .map-layer.visible {
      z-index: 100;
    }
  }

  //
  // Single service location
  //
  .sidepane-service-location {
    display: flex;
    flex-flow: column;
    background: #fff;
    height: 100%;
    width: $sidepane-width;
    position: absolute;
    z-index: 1;

    @media (min-width: $breakpoint-3-1) {
      margin-left: -$sidepane-btn-width;
    }

    .sidepane-service-location-header-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: $sidepane-width;
      padding: 0 20px;
    }

    .sidepane-service-location-content {
      padding: 0 20px 20px;
    }

    .sidepane-service-location-header-row-close-button {
      background: #fff;
      padding-right: 0px;

      svg.button-icon {
        max-height: 24px;
        max-width: 24px;
        color: $color-gray-1;
      }
    }

    .service-hours-item-title {
      margin-top: 15px;
    }
  }
}

//
// Mobile map layer selection
//
.mobile-map-layer-selection-container {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  background: #fff;

  .map-layer {
    background-size: cover;
    width: 100%;
    height: 100px;
    padding: 0;
    margin-bottom: 20px;
    position: relative;
    border: 2px solid $gray-60;

    &.current-layer {
      border-color: $red;
    }

    &.selected-layer {
      border-color: $lake-light;
    }

    .layer-name {
      background-color: #fff;
      overflow: hidden;
      text-align: left;
      font-size: 18px;
      line-height: 27px;
      padding: 5px;
      display: block;
      color: #000;
      opacity: 0.8;
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }

  // layer ids come from oskari
  .layer-1 {
    background: url(#{$image_base_path}/images/map_layer_buttons/mobile_background_map.png);
  }

  .layer-3 {
    background: url(#{$image_base_path}/images/map_layer_buttons/mobile_orthophotos.png);
  }

  .layer-382 {
    background: url(#{$image_base_path}/images/map_layer_buttons/mobile_topographic_map.png);
  }
}
