.instructions-content-area {
  h2 {
    margin-bottom: 15px;
  }
}

.instruction-groups {
  margin-bottom: $fi-spacing-xxl;
}

.instruction-services {
  margin-bottom: $fi-spacing-xxl;
}

.instruction-group-view-links {

  padding: 10px 25px;

  .arrow-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.top-level-instructions {
  margin-bottom: $fi-spacing-xxl;
  background: $fi-color-white-base;

  box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);
  border-radius: 4px;

  a:hover, &:active {
    text-decoration: none;
  }
}

.highlighted-instructions {
  margin-bottom: $fi-spacing-xxl;

  .highlighted-instruction {
    background: $fi-color-white-base;
    box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);
    border-radius: 4px;
    height: 100%;

    a:hover, &:active {
      text-decoration: none;
    }

    @media(min-width: $breakpoint-4) {
      transform: scale(1);
      transition: transform ease-in-out 0.1s;
      &:hover {
        transform: scale(1.03);
      }
    }

  }
}

.instruction-group-service-guides {
  margin-bottom: $fi-spacing-xxl;
}

.instruction-content-area {
  margin-bottom: $fi-spacing-xxl;
}

.child-instruction-links {
  margin-bottom: $fi-spacing-xxl;

  .arrow-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
