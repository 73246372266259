﻿.service-hours-item {
  @include rem(margin-bottom, 15px);
}

.service-hours-item-title {
  @include rem(margin-bottom, 10px);
  font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial;
}
.legistation-item {
  @include rem(margin-bottom, 15px);
  &:last-child {
    @include rem(margin-bottom, 40px);
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/******** PHONE CHANNEL STYLES *********/
.phone-channel-subject {
  span {
    vertical-align: top;
  }
  .icon svg {
    max-height: 30px;
    max-width: 30px;
    @include rem(margin-right, 10px);
  }
}

.phone-channel-number {
  @include rem(margin-right, 9px)
}

.info-popup-item {
  cursor: pointer;
}

.info-button-container {
  vertical-align: middle;
}

.support-contact-dynamic-content-wrapper span a {
  display: inline-block;
  width: 98%;
}

.info-popup {
  display: inline-block;
}

.info-popup-tooltip {
  position: relative;
  background: $fi-color-white-base;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  font-size: 16px;
  outline: 0;
  z-index: 9999;

  &[data-reference-hidden] {
    visibility: hidden;
  }
}

.quick-info-button-small {
  background: #fff;
  border-radius: 50%; // for :focus/active outline
  margin: 0 2px;
  height: 15px;
  width: 15px;
  padding: 0;

  svg.fi-icon {
    margin-left: 0;
    width: 15px;
    color: $lake;
  }

  &:hover {
    background: #fff;
  }
}

.login-icon svg.button-icon {
  color: #fff;
  vertical-align: middle;
  margin-bottom: 2px;
}

/******** PHONE CHANNEL STYLES END *********/

.services-in-other-languages {
  text-align: center;
  background: #fff;
  border: 1px solid $depthLight1;
  padding: 20px;
  word-wrap: break-word;
}
.no-services-in-selected-language {
  font-family: SourceSansProSemibold,SourceSansProRegular,'Helvetica Neue',Arial;
  padding: 40px;
}


.organization-list-item {
    border-top: 1px solid $gray-60;
    border-right: 1px solid $gray-60;
    border-left: 1px solid $gray-60;
    padding: 20px 25px 35px 25px;
    background: white;

    &:last-of-type {
      border-bottom: 1px solid $gray-60;
      margin-bottom: 30px;
    }
}
