.suomifi-color-theme {
  --theme-process-chart: #{$fi-color-accent-tertiary-dark1};
}

.developer-color-theme {
  --theme-process-chart: #{$color-suomi};
}


.process-chart {

  &.siha-content {
    margin: 30px 0;
  }

  &.checklist-part {
    margin: 30px -25px;
  }

  &.content-page-part {
    margin: 30px -30px;
  }

  $single-column-breakpoint: $breakpoint-2-1;
  $dual-column-breakpoint: $breakpoint-2;
  $highlightLight2: $fi-color-highlight-light2;
  $highlightLight3: $fi-color-highlight-light3;
  $accentTertiaryDark1: var(--theme-process-chart);
  $whiteBase: $fi-color-white-base;
  $small-dot-radius: 14px;
  $large-dot-radius: 16px;
  $timeline-thickness: 2px;
  $task-margin: 30px;
  $single-column-task-margin: 70px;
  $single-column-timeline-position: 36px;
  $task-border-thickness: 1px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $highlightLight3;
  margin: 30px -30px;
  padding: 40px 15px 90px 15px;
  @include word-wrap;

  @mixin timeline() {
    content: '';
    position: absolute;
    width: $timeline-thickness;
    background: $accentTertiaryDark1;
  }

  @mixin dot($radius) {
    content: '';
    position: absolute;
    width: $radius;
    height: $radius;
    background: $accentTertiaryDark1;
    border-radius: 50%;
  }

  @function dot-offset($radius) {
    @return $radius * 0.5
  }

  @mixin pointer-left {
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid white;
    top: 10px;
    left: -10px;
    transform: translateX(-50%);
  }

  @mixin pointer-right {
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
    border-right: 10px solid transparent;
    top: 10px;
    left: calc(100% + 10px);
    transform: translateX(-50%);
  }

  .start {
    &::before {
      @include timeline();
      top: 35px;
      height: 30px;
      left: calc(50% - #{$timeline-thickness * 0.5});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - $timeline-thickness * 0.5;
      }
    }

    &::after {
      @include dot($large-dot-radius);
      top: 25px;
      left: calc(50% - #{dot-offset($large-dot-radius)});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - dot-offset($large-dot-radius);
      }
    }
  }

  .end {
    &::before {
      @include timeline();
      bottom: 60px;
      height: 35px;
      left: calc(50% - #{$timeline-thickness * 0.5});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - $timeline-thickness * 0.5;
      }
    }

    &::after {
      @include dot($large-dot-radius);
      bottom: 45px;
      left: calc(50% - #{dot-offset($large-dot-radius)});

      @media (max-width: $single-column-breakpoint) {
        left: $single-column-timeline-position - dot-offset($large-dot-radius);
      }
    }
  }

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 30px;
    width: 100%;

    @media (max-width: $single-column-breakpoint) {
      align-items: start;
    }

    @media (min-width: $dual-column-breakpoint) {
      &.user {
        .task {
          align-self: flex-start;

          .pointer {
            @include pointer-right();
          }

          .timeline-dot {
            left: calc(100% + #{$task-margin + $task-border-thickness - dot-offset($small-dot-radius)});
          }

          .timeline-before {
            left: calc(100% + #{$task-margin + $task-border-thickness - $timeline-thickness * 0.5});
          }

          .timeline-after {
            left: calc(100% + #{$task-margin + $task-border-thickness - $timeline-thickness * 0.5});
          }
        }
      }
      &.officer {
        .task {
          align-self: flex-end;

          .pointer {
            @include pointer-left();
          }

          .timeline-dot {
            left: - $task-margin - $task-border-thickness - dot-offset($small-dot-radius);
          }

          .timeline-before {
            left: - $task-margin - $task-border-thickness - $timeline-thickness * 0.5;
          }

          .timeline-after {
            left: - $task-margin - $task-border-thickness - $timeline-thickness * 0.5;
          }
        }
      }
    }

    .fi-heading {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .task {
      position: relative;
      margin-top: 25px;
      background-color: $whiteBase;
      border: $task-border-thickness solid $highlightLight2;
      width: calc(50% - #{$task-margin});

      .timeline-dot {
        @include dot($small-dot-radius);
        top: 12px;
      }

      .timeline-before {
        @include timeline();
        top: -30px;
        height: 39px;
        left: -$timeline-thickness * 0.5
      }

      .timeline-after {
        @include timeline();
        top: 30px;
        height: calc(100% - 5px + #{$task-border-thickness});
        left: -$timeline-thickness * 0.5
      }

      @media (max-width: $single-column-breakpoint) {
        width: calc(100% - 55px);
        align-self: flex-end;

        .pointer {
          @include pointer-left();
        }

        .timeline-dot {
          left: -$single-column-task-margin + $single-column-timeline-position - $task-border-thickness - dot-offset($small-dot-radius);
        }

        .timeline-before {
          left: -$single-column-task-margin + $single-column-timeline-position - $task-border-thickness - $timeline-thickness * 0.5;
        }

        .timeline-after {
          left: -$single-column-task-margin + $single-column-timeline-position - $task-border-thickness - $timeline-thickness * 0.5;
        }
      }

      .content {
        padding: 10px 15px;

        * {
          font-size: 16px;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }

      .ptv-content-container {
        margin: 10px 4px 0 4px;
      }

      .sihard-related-services-list-header {
        padding: 10px;

        .sihard-related-services-list-municipality-selector {
          margin-top: $fi-spacing-xs;
        }

        > div {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .related-services-list-item {
        padding: 30px 15px 20px 15px;
      }
    }
  }

  .process-chart-step-icon {
    align-self: center;
    line-height: 0;
    padding: 4px;
    border: 2px solid $accentTertiaryDark1;
    border-radius: 50%;
    width: 52px;
    background-color: $whiteBase;

    @media (max-width: $single-column-breakpoint) {
      align-self: start;
      width: 42px;
    }

    > svg {
      height: 40px;

      @media (max-width: $single-column-breakpoint) {
        height: 30px;
      }
    }
  }
}
