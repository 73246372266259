.page-footer {
  background: #fff;
  border-top: 4px solid $color-suomi;

  .footer-main {
    @include rem(padding-bottom, $fi-spacing-xl);
    @include rem(padding-top, $fi-spacing-xl);

    @media (min-width: $breakpoint-4) {
      @include rem(padding-bottom, $fi-spacing-m);
      @include rem(padding-top, $fi-spacing-xxl);
    }
  }

  .footer-some {
    @include rem(padding-bottom, $fi-spacing-l);
    @include rem(padding-top, $fi-spacing-xl);
    display: flex;
    background-color: $color-suomi;
  }

  .site-description-container {
    @include rem(margin-bottom, $fi-spacing-m);
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-4) {
      flex-direction: row;
      @include rem(margin-bottom, $fi-spacing-xxl);
    }

    svg {
      width: auto;
      height: 42px;
    }
  }

  .footer-suomifi-logo {
    @include rem(margin-bottom, $fi-spacing-s);
    display: block;
    align-self: flex-start;
    svg {
      pointer-events: none;
    }
    @media (min-width: $breakpoint-4) {
      @include rem(margin-bottom, 6px);
      @include rem(margin-right, -25px); // Compensate for extra whitespace inside SVG when scaling up
      @include rem(margin-top, 6px);
    }
  }

  .footer-site-description {
    @media (min-width: $breakpoint-4) {
      @include rem(border-left, 1px solid #c8cdd0);
      @include rem(margin, 0);
      @include rem(padding-left, 17px);
      @include rem(width, 340px);
      align-self: flex-start;
      display: inline-block;
    }
  }

  .link-list {
    @include rem(font-size, 16px);
    > * {
      @media (min-width: $breakpoint-4) {
        @include rem(margin-right, $fi-spacing-xxl);
        display: inline-block;
      }
    }
  }

  .your-europe-logo-link {
    display: inline-block;
    line-height: 0;
    @include rem(margin-bottom, 50px);

    @media (min-width: $breakpoint-3) {
      @include rem(margin-bottom, 0);
    }
  }

  .your-europe-logo {
    @include rem(width, 254px);
  }

  .your-europe-logo-container {
    @include rem(margin-top, $fi-spacing-xl);
    @include rem(padding-bottom, 50px);
    @media (max-width: $breakpoint-4-1) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-some-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-bottom: solid 1px white;
    @include rem(padding-bottom, $fi-spacing-xl);

    @media (max-width: $breakpoint-4-1) {
      justify-content: center;
    }

    @media (min-width: $breakpoint-3) {
      @include rem(padding-bottom, $fi-spacing-l);
      flex-direction: row;
    }

    @media (max-width: $breakpoint-3-1) {
      @include rem(margin-right, 0);
      @include rem(margin-left, 0);
      @include rem(padding-bottom, $fi-spacing-xxs);
    }
  }

  .footer-some-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    @media (min-width: $breakpoint-3) {
      flex-direction: row;
    }
  }

  // Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
  .footer-some-link-container.footer-some-link-container {
    display: flex;
    align-items: center;
    @include rem(margin-bottom, $fi-spacing-l);
    color: #fff;

    @media (min-width: $breakpoint-3) {
      @include rem(margin-bottom, 0);
      @include rem(margin-left, $fi-spacing-m);
    }

    @media (min-width: $breakpoint-3) {
      @include rem(margin-left, $fi-spacing-xl);
    }
  }

  .footer-x-logo {
    display: inline;
    @include rem(height, $fi-spacing-m);
    @include rem(width, $fi-spacing-m);
    @include rem(margin-right, 8px);
  }

  .footer-facebook-logo {
    display: inline;
    @include rem(height, $fi-spacing-m);
    @include rem(width, $fi-spacing-m);
    @include rem(margin-right, $fi-spacing-xs);
  }

  .footer-youtube-logo {
    display: inline;
    @include rem(height, $fi-spacing-m);
    @include rem(width, $fi-spacing-xl);
    @include rem(margin-right, $fi-spacing-xs);
  }

  .footer-instagram-logo {
    display: inline;
    @include rem(height, $fi-spacing-m);
    @include rem(width, $fi-spacing-m);
    @include rem(margin-right, $fi-spacing-xs);
  }
}
