.event-view {
  .event-sidebar-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: $fi-spacing-s;
  }

  .content-view {
    .fi-paragraph:last-child {
      margin-bottom: 0px !important;
    }
  }

  .content-view-desktop-right {
    .event-sidebar-title {
      margin-bottom: $fi-spacing-s;
    }
  }

  .content-view-mobile-related-content {
    background-color: $whiteBase;
  }

  .event-sidebar-title h3 {
    margin: 0px;
  }
}

.events {
  .related-services-list-item {
    padding-bottom: 0;
  }
  .search-results-list {
    margin-bottom: 0;
  }
  .events-group-heading {
    border-left: 1px solid $fi-color-depth-light1;
    border-right: 1px solid $fi-color-depth-light1;
    background-color: $fi-color-white-base;
    padding: $fi-spacing-m;
  }
  .events-duration {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: $fi-spacing-xxs;
  }
}

.filters-details:has(+ .events) {
  border-bottom: 1px solid $fi-color-depth-light1;
}

.developer-events-section {
  background-color: $fi-color-highlight-dark1;
  h2 {
    color: $fi-color-white-base;
  }
  .container {
    display: flex;
    padding: $fi-spacing-xxl 0 $fi-spacing-xxxl 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media(max-width: $breakpoint-3-1) {
      padding: $fi-spacing-xl $fi-spacing-s $fi-spacing-xxl $fi-spacing-s;
      align-items: flex-start;
    }
  }
  .no-upcoming-events-text {
    color: $fi-color-white-base;
  }
  .event-row {
    display: flex;
    flex-direction: row;
    gap: $fi-spacing-s;
    @media(max-width: $breakpoint-3-1) {
      flex-direction: column;
      align-self: stretch;
    }
  }
  .developer-event-item {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    background-color: $fi-color-white-base;
    border-radius: $fi-spacing-inset-xs;
    .event-icon {
      width: 24px;
      height: 24px;
    }
    a.fi-link {
      &:not(:visited) {
        color: $fi-color-highlight-base;
      }
      font-weight: 600;
    }
  }
  .events-link {
    color: $fi-color-white-base;
    text-decoration: underline;
    text-underline-offset: 2px;
    &:visited, &:hover, &:focus {
      color: $fi-color-white-base;
    }
  }
}

