.my-page-registeries {
    margin-bottom: 50px;

    .register-logo {
      max-width: 220px;
      text-align: center;
      margin: 0 auto;
      img {
        max-height: 100px;
      }
    }
    .my-page-register {
      margin-top: 20px;
      padding-top: 15px;
      text-align: center;
      @media (min-width: $breakpoint-4) {
        text-align: left;
      }

      .register-view-data-button {
        display: block;
        margin-top: 0.5em;
      }

      .more-info {
        margin-top: 20px;
        position: relative;
        text-align: left;
        .close {
          position: absolute;
          top: 15px;
          right: 0;
          display: flex;
          cursor: pointer;

          .fi-icon {
            color: $themecolor;
            @include rem(font-size, 28px);
            margin: 0 8px 0 0 !important;
          }
        }

        .top {
          position: relative;
          border-top: 1px solid $color-gray-8;
          margin: 0 0 50px 0;
          &:after {
            top: -1px;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #fff;
            border-width: 15px;
            margin-left: -15px;
          }
          &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(206, 210, 213, 0);
            border-top-color: #ced2d5;
            border-width: 15px;
            margin-left: -15px;
          }
        }
      }
    }
  }

.register-list {
    background: #fff;

    &.logged-out {
      margin-top: 30px;
    }
}

.register-list-item {

    border-top: 1px solid $gray-60;
    border-right: 1px solid $gray-60;
    border-left: 1px solid $gray-60;
    &:last-of-type {
        border-bottom: 1px solid $gray-60;
    }
    padding: 20px 15px 35px 15px;

    &-logo {
      align-self: center;
      flex: 0 0 auto;
      @media (max-width: $breakpoint-4-1) {
        align-self: flex-start;
        margin-bottom: 15px;
      }
    }

    &-header {
      margin-top: 0;
      @include rem(margin-bottom, 5px);
      @include rem(font-size, 20px);
      @include rem(line-height, 26px);
    }

    &-producer {
      margin: 0;
      display: block;
      @include rem(margin-bottom, 5px);
      @include rem(font-size, 16px);
      @include rem(line-height, 20px);
      color: $gray-k40;
    }

    &-content-type {
      @include rem(font-size, 12px);
      @include rem(line-height, 15px);
      color: $gray-k40;
      text-transform: uppercase;
      margin-top: 0;
      font-family: SourceSansProSemiBold,SourceSansProRegular,"Helvetica Neue",Arial;
      @include rem(margin-bottom, 15px);
    }

    &-content {
      margin: 0;
    }
}
