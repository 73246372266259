.form-group {
  margin-bottom: 1rem;
}

// @at-root is used to scope variables here
@at-root {
  .checkbox-wrapper {
    margin-top: $fi-spacing-s;
    @media(min-width: $breakpoint-4-1) {
      margin-top: $fi-spacing-xs;
    }

    &.no-margin {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

input,
select,
textarea {
	@include rem(font-size, 18px);
	font-family: SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
}

textarea,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
input[type="date"],
input[type="number"],
input[type="password"],
input[type="file"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
	transition: all 0.2s ease;
	background: #fff;
	border: 1px solid $gray;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2) inset;
	@include border-radius(2px);
	box-sizing: border-box;
	font-weight: 400;
	@include rem(padding, 10px);
	@include rem(line-height, 20px);
	@include rem(font-size, 16px);
	text-transform: none;
	width: 100%;
	max-width: 100% !important;
	vertical-align: top;
	&:focus {
		border-color: $lake;
		box-shadow: 0 0 3px 0 $lake;
		outline: 0;
	}
	&.input-error {
		border-color: $red;
	}
}

textarea.disabled,
input.disabled,
textarea[disabled],
input[disabled]{
	background-color: $color-light-gray;
	border-color: $color-gray-8;
	color: $color-gray-5;
	&:hover {
		cursor: not-allowed;
	}
}

.form-control {
	height: auto;
}

.form-control:disabled {
	background-color: $gray-10;
}

// .form-rows is used twice to override `display: inline-block` on TextInputs which uses selector with 2 classes.
.form-rows.form-rows > * {
  display: block;
  &:not(:last-child) {
    margin-bottom: $fi-spacing-m;
  }
}
