@import 'colors';

.mandate-request-landing {
  .bg-lake-el {
    background-color: $lake-extralight;
  }

  .services-list {
    list-style: disc;
    margin-left: 30px;
  }
}
