.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.7);
  cursor: pointer;
}

.lightbox-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;

  .lightbox-image-wrapper {
    overflow: auto;
    line-height: 0;
  }

  .lightbox-content-header {
    text-transform: uppercase;
    .fi-paragraph {
      text-align: right;
    }
    .fi-icon {
      margin-left: 10px;
    }
  }
}

.siha-content-image-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div:last-child{
    span {
      vertical-align: middle;
    }
  }
  svg {
    vertical-align: middle;
  }
  @media(max-width: $breakpoint-4-1) {
    flex-direction: column;
  }
}

.siha-content-image {
  border: 1px solid $fi-color-depth-light1;
  cursor: pointer;
}

.lightbox-open-button {
  @media(max-width: $breakpoint-4-1) {
    padding-left: 0;
  }
}
