.ott-service-related-info-item {
  margin-bottom: 23px;
}

.ott-information-row:not(:last-child) {
  padding-bottom: $fi-spacing-xs;
}

.ott-subsection:last-child {
  .ott-subsection-box:last-child {
    margin-bottom: 0;
  }
}

.ott-subsection {
  h3 {
    margin-top: 0;
    margin-bottom: $fi-spacing-m;
  }

  .ott-subsection-box {
    background-color: $highlightLight4;
    border: solid 1px $depthLight1;
    padding: 20px 15px 20px 15px;
    margin-bottom: 25px;

    .ott-modal-button {
      margin-left: -20px;
      margin-top: -5px;
      font-size: 18px;
      line-height: 1.5;
      font-weight: 400;
    }
  }
}

.address-change-notification-list {
  margin-bottom: 30px;

  .ott-subsection-box {
    padding-bottom: 40px;
  }
}

.ott-checkbox-row {
  margin-bottom: $fi-spacing-xs;
  @media(max-width: $breakpoint-4-1) {
    margin-bottom: $fi-spacing-m;
  }

  .ott-checkbox-row-checkbox-cell, .ott-checkbox-row-checkbox-cell-mobile {
    display: flex;
    align-items: center;
  }

  .ott-checkbox-row-checkbox-cell-mobile {
    font-weight: 600;
  }

  .ott-checkbox-row-value-cell-mobile {
    font-size: 16px;
    margin-left: 2.5rem;
  }
}

.ott-divider {
  margin-top: $fi-spacing-l;
  margin-bottom: $fi-spacing-l;
}

.ott-tooltip {
  margin-left: 3px;
  .info-button-container {
    vertical-align: top;
  }

  .info-popup {
    .rich-popover-title {
      p, div {
        font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
        font-weight: 600;
      }
    }
    p, span {
      font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', sans-serif;
      font-weight: 400;
    }
  }
}

.ott-form-label {
  margin-bottom: 10px;
  font-family: 'Source Sans Pro','Helvetica Neue','Arial',sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: hsl(0,0%,16%);
}

.ott-editable-column-current-value:not(:empty) {
  margin-bottom: 5px;
}

.ott-subtitle {
  font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  .info-popup {
    margin-left: 5px;
    p {
      font-family: 'Source Sans Pro','Helvetica Neue','Arial',sans-serif;
      font-weight: 400;
    }
  }
}

.ott-value {
  font-size: 16px;
  line-height: 24px;

  .info-popup {
    margin-left: 5px;
  }
}

.ott-inline-alert {
  margin-bottom: -30px;
}
