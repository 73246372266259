.feedback-form-container {

  h2, h3 {
    margin-bottom: 10px;
  }

  h5 {
    margin-bottom: 7px;
  }

  .feedback-form-field-group {
    margin-bottom: 30px;
  }

  .feedback-form-field {
    .feedback-form-field-header {
      margin-bottom: 10px;
    }
    label {
      display: block;
      font-family: inherit;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    margin-bottom: 10px;
  }

  .info-popup-item {
    margin-left: 5px !important;
  }
}

.ptv-feedback-send {
  padding: 7px 0 10px 0;
  @media(max-width: $breakpoint-2-1) {
    button {
      width: 100%;
    }
  }
}

.captcha-button-container {
  margin-top: auto;
  margin-bottom: 0;
  @media (max-width: $breakpoint-2-1) {
    display: flex;
    justify-content: center;
  }
}

.feedback-expander-container {
  .feedback-expander-content {
    font-size: 16px;
  }
}
