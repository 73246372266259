@mixin word-wrap() {
  word-wrap: break-word;
  word-break: break-word;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin card {
  background: #fff;
  border: 1px solid $gray-60;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  @include rem(padding, 15px);
}

@mixin triangleBottomCentered($height, $width, $color) {
  position: relative;
  &:after {
    position: absolute;
    content: '';
    border-top: $height solid $color;
    border-bottom: $height solid transparent;
    border-left: $width * 0.5 solid transparent;
    border-right: $width * 0.5 solid transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
