.related-service-channels-list.scrollable-list, .related-services-list.scrollable-list {
  @media (min-width: $screen-sm-min) {
    border-top: 1px solid $color-gray-8;
    height: 600px;
    max-height: calc(100vh - 20px);
    overflow-y: scroll;
  }

  .related-services-list-item {
    padding-bottom: 20px;
  }
}

.related-service-channels-list, .related-services-list {
  .related-services-list-item:focus-within {
    border-top: 2px solid $color-persimmon;
    border-bottom: 2px solid $color-persimmon;
  }
}

.service-join {
  @include rem(font-size, 16px);
  @include rem(margin-top, 15px);
  li {
    list-style-type: disc;
    margin-left: 20px;
  }
}

.map-link-row {
  @include rem(font-size, 18px);
  font-weight: 400;
  margin-top: 20px;
  .fi-icon {
    margin-right: 5px;
  }
}

.ptv-highlight-box {
  background-color: $highlightLight4;
  border: solid 1px $depthLight2;
  padding: 20px 20px 30px 20px;
  @include rem(margin-top, 40px);
  @include rem(margin-bottom, 40px);

  h2 {
    margin-top: 0;
  }
}

.form-files-box {
  background-color: #fff;
  @include rem(margin-top, 20px);
  @include rem(padding, 20px);

  .form-id-label {
    color: $depthDark1;
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
  }
}

.contact-details-block {
  @include rem(margin-bottom, 15px);
}

.phone-service-channel-expander {
  .fi-expander_content {
    overflow: visible;
  }
}
