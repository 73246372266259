.sihard-step-component {
  :not(.sihard-checklist-feedback) > .siha-feedback-form-container {
    .active-form-container {
      border: 1px solid $depthLight2;
      box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.20);
    }
  }
}

.siha-feedback-form-container {
  $single-column-breakpoint: $breakpoint-2-1;

  .form-container-base {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .active-form-container {
    @extend .form-container-base;
    border: 1px solid $depthLight1;
    background: $whiteBase;
    box-shadow: none;
    margin-top: 30px;
  }

  .thanks-form-container {
    @extend .form-container-base;
    background-color: $depthLight2;
  }

  .focus-styled-heading {
    text-align: center;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 2px;
    box-shadow: 0 0 $blackBase;

    &:focus {
      box-shadow: 0 0 0 2px $accentSecondary;
    }
  }

  .status-text {
    color: $depthDark2;
  }

  .emoticon-button-container {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;

    @media (max-width: $single-column-breakpoint) {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
    }

    .emoticon-button-label-wrapper {
      display: flex;
      justify-content: center;

      .label-base {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      .label-unpressed {
        @extend .label-base;
        .icon {
          color: $highlightBase;
          width: 16px;
          height: 16px;
        }
      }

      .label-pressed {
        @extend .label-base;
        .icon {
          color: $whiteBase;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
