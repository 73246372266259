.filter-services {
  display: flex;
  flex-direction: column;
  border: 1px solid $depthLight1;
  padding: 20px 20px 40px;
  background: $whiteBase;
  color: $blackBase;

  h3 {
    margin-top: 0;
  }

  hr {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  @media (max-width: $breakpoint-3-1) {
    padding: 0;
  }
}

.filter-subgroup:not(:last-child) {
  margin-bottom: 20px;
}

.embedded-filter {
  display: flex;
  flex-direction: column;
  border: 1px solid $depthLight1;
  background: $depthLight2;
  color: $black;

  .filter-header {
    font-weight: 600;
    padding: 15px 20px;
    margin: 0;
  }

  .filter {
    padding: 0 20px 20px 20px;
  }

  .details {
    padding: 10px 20px;
    font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
    font-weight: 600;
  }
}

.clear-service-filters {
  margin-bottom: 0;
  margin-top: 15px;
  .inline-icon {
    margin: 0 5px 0 0;
  }
}

.filters-details {
  .active-filters {
    margin-top: 10px;
  }
}

.filter-section-header {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.filter-section-footer {
  margin-top: 6px;
}

.filter-daterange-group {
  .date-picker-wrapper {
    margin-top: 10px;
    padding-left: 0;
    max-width: 200px;
    min-width: 120px;
  }
}

.fi-date-picker {
  @media (max-width: $screen-sm-max) {
    // On top of react-modal
    z-index: 950;
  }
}
