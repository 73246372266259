.frontpage-news-stream {
  border: 1px solid $gray-60;
  background-color: white;
  @include rem(padding-left, 15px);
  @include rem(padding-right, 15px);
  padding-bottom: 20px;
  li {
    padding: 20px 0;
    border-top: 1px solid $gray-60;
    &:first-child{
      border: none;
    }
  }

  p {
    margin: 0;
  }

  .date,
  .news-stream-item-date {
    @include rem(font-size, 16px);
  }
  .frontpage-news-stream-title {
    @include rem(font-size, 20px);
  }
}

.frontpage-news-stream-container {
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: $breakpoint-4-1) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  > ul {
    margin-bottom: 10px;
  }
}

.frontpage-news-section {
	padding-top: 20px;
	padding-bottom: 50px;
}

.frontpage-highlight-box {
  text-align: center;
  background: #fff;
  border: 1px solid $gray-60;
  border-top: 3px solid $color-suomi;
  height: 100%;
  padding: 20px;
  hyphens: none;
  -webkit-hyphens: none;
  display: flex;
  flex-direction: column;
  h3 {
    margin-top: 0;
  }
  .centered-md {
    margin-top: auto;
  }
}

.frontpage-content-area {
  margin-bottom: -$footer-margin-top;
}

.frontpage-background-medium {
  background: $fi-color-highlight-light2;
}

.frontpage-background-light {
  background: $fi-color-depth-secondary;
}

.frontpage-background-white {
  background: $fi-color-white-base;
}

.frontpage-services {
  margin-bottom: $fi-spacing-xxl;
}

.frontpage-shortcuts {
  margin-bottom: $fi-spacing-xxl;
}

.frontpage-news {
  display: flex;
  flex-direction: column;
  margin-bottom: $fi-spacing-xl;

  @media (min-width: $breakpoint-4) {
    flex-direction: row;
  }

  .frontpage-news-item {
    display: flex;
    flex-direction: column;
    padding: $fi-spacing-m 0;
    flex-basis: 0;
    flex-grow: 1;

    @media (min-width: $breakpoint-4) {
      padding: $fi-spacing-m;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $fi-color-depth-light1;

      @media (min-width: $breakpoint-4) {
        border-bottom: none;
        border-right: 1px solid $fi-color-depth-light1;
      }
    }

    .frontpage-news-item-title {
      order: 2;
      @extend .semibold;
    }

    .frontpage-news-item-date {
      order: 1;
    }
  }
}

.frontpage-news-archive-link {
  margin-bottom: $fi-spacing-xxl;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: $breakpoint-4) {
    text-align: center;
  }
}

.frontpage-suomifi-in-nutshell {
  padding-top: $fi-spacing-xxl;
  padding-bottom: $fi-spacing-xxxxl;

  .frontpage-suomifi-in-nutshell-title {
    margin-bottom: $fi-spacing-xs;
    margin-top: $fi-spacing-xl;
    @media(min-width: $breakpoint-4) {
      margin-top: 0;
    }
  }
}

.frontpage-developer-ad {
  padding: $fi-spacing-xl 0;

  .developer-ad-link {
    width: fit-content;
    margin: 0 auto;
    display: block;

    &:hover, &:focus {
      text-decoration: none;
      
      .container {
        box-shadow: 0 4px 12px rgba(41, 41, 41, 0.3);
      }
      
      .arrow-item {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 2px;
      }
    }
  }

  .arrow-item {
    color: $fi-color-highlight-base;
  }

  .container {
    display: grid;
    padding: 0;
    grid-template-columns: 1fr 40px 1fr;
    background: $fi-color-white-base;
    border-top: 5px solid $kehittajilleTurkoosi;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(41, 41, 41, 0.2);

    @media (max-width: $breakpoint-4-1) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      height: 600px;
    }
  }

  .divider {
    width: 106px;
    height: 3px;
    background-color: $kehittajilleTurkoosi;
    margin-bottom: $fi-spacing-m;
    margin-top: $fi-spacing-m;
  }

  .frontpage-developer-ad-content {
    grid-column: 1;
    padding: $fi-spacing-xl;

    @media (max-width: $breakpoint-4-1) {
      grid-row: 2;
    }
  }

  .frontpage-developer-ad-image {
    grid-column: 3;
    overflow: hidden;

    @media (max-width: $breakpoint-4-1) {
      grid-column: 1;
      grid-row: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

#frontpage-hero-area {
  margin-top: -$header-margin-bottom;
  $hero-background-base-color: rgb(35, 90, 154);
  background: $hero-background-base-color;
  background: radial-gradient(circle 500px at 50% 0%, rgb(42, 110, 187) 0%, $hero-background-base-color 100%);

  h1 {
    margin-top: 50px;
    margin-bottom: 53px;
    hyphens: none;
    -webkit-hyphens: none;
    max-width: 100%;
  }

  @media (max-width: $breakpoint-4-1) {
    padding: 15px 0;
    text-align: center;

    h1 {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }

  .frontpage-spotlights {
    padding-bottom: 28px;

    .frontpage-spotlights-show-more-button {
      background: $hero-background-base-color;
      &:hover {
        background: linear-gradient(-180deg, mix(#fff, $hero-background-base-color, 10%) 0%,$hero-background-base-color 100%);
      }
      &:active {
        background: $hero-background-base-color;
      }
    }
  }
}
