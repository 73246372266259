.map-container {
  .map-sidepanel-container .map-search-panel, .map-sidepanel-container-mobile .map-search-panel {
    padding: 15px 20px 20px 20px;

    .document-title h1 {
      padding-bottom: 10px;
      margin: 0;
      @extend .visual-h4;
      @extend .semibold;
    }
  }
}

.do-search-panel {
  text-align: center;
  padding-top: 20px;

  .service-locations-info {
    @include rem(font-size, 22px);
    margin-bottom: 20px;
    @extend .semibold;
  }

  .service-locations-search {
    @include rem(font-size, 16px);
  }

  .service-locations-count {
    margin: 10px auto;
    width: 120px;
  }
}
