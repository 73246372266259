.icon {
  display: inline-block;
  transform: translate3d(0, 0, 0);
}

.icon-with-border .icon {
  border: 1px solid #c9cdcf;
  border-radius: 50%;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

svg.button-icon {
  vertical-align: sub;
  pointer-events: none;
  margin-right: 8px;
  fill: #fff;
  height: 16px;
  width: 16px;
  &.after {
    margin-right: 0;
    margin-left: 8px;
  }
}

svg.inline-icon {
  vertical-align: baseline;
  pointer-events: none;
  margin-left: 5px;
  max-height: 15px;
  width: 18px;
  fill: $lake;
}
