.subnav {
	background: $lake-extralight;
	border: 1px solid $gray-60;
	@media (min-width: $breakpoint-4) {
		background: #fff;
	}
  @media (max-width: $breakpoint-4-1) {
    margin-top: $margin*2;
  }

	#nav-label {
		margin-top: 0;
		text-transform: uppercase;
		border-bottom: 1px solid $gray-60;
		padding-bottom: 10px;

		@media (max-width: $breakpoint-4-1) {
			border-bottom: 1px solid #fff;
		}
	}

	@media (max-width: $breakpoint-4-1) {
		&.closed {
			#nav-label {
				border-bottom: none;
				margin-bottom: 0;
				padding-bottom: 0;
			}

			.nav-tree {
				display: none;
			}
		}
	}

	padding: 10px 0 10px 10px;
	margin-bottom: $margin*2;

	> ul {
		margin-left: -10px;
	}
	@include rem(font-size, 16px);

	.old-button {
		background: none;
		border: none;
		color: inherit;
		padding: 0;
		text-align: left;
		-webkit-transition: all 0.4s;
		transition: all 0.4s;
	}

	.toggle-subnav {
		margin: 0 !important;
		padding: 0 !important;
		display: block;
		width: 100%;

    &.opened {
      .expander-icon {
        transform: rotate(90deg);
      }
    }

		@media (min-width: $breakpoint-4) {
			&:focus, &:hover {
				cursor: default;
			}
		}

		@media (max-width: $breakpoint-4-1) {
			.expander-icon {
				display: inline-block;
				border: none;
        height: 20px;
        width: 20px;
				float: right;

      }
		}
	}
}
