.my-event-history {
  .event-header {
    @include fi-text-body-semi-bold-small;
    border-bottom: 2px solid $fi-color-highlight-base;
    margin: 0;
    padding: $fi-spacing-s 0;
  }

  .event-header + .event-row.first {
    border-top: 0;
  }

  .event-row {
    @include fi-text-body-text-small;
    margin: 0;
    padding: $fi-spacing-s 0;

    &:not(:last-child) {
      border-bottom: 1px solid $fi-color-depth-light1;
    }

    &.first {
      border-top: 2px solid $fi-color-depth-base;
    }

    &:nth-child(even) {
      background: $fi-color-depth-light3;
    }

    .fi-link {
      font-size: 16px;
    }
  }

  .event-date {
    @include fi-text-body-semi-bold-small;
  }

  .event-history-list {
    background: $fi-color-white-base;
    border: 1px solid $fi-color-depth-light1;
    margin-bottom: $fi-spacing-xl;
  }

  .event-info-container {
    background: $fi-color-white-base;
    border: 1px solid $fi-color-depth-light1;
    padding: $fi-spacing-m;
  }

  @media (max-width: $screen-sm-max) {
    .event-header {
      @include visually-hidden;
    }

    .event-row {
      background: $fi-color-depth-light3;
      padding: 0 0 $fi-spacing-s 0;

      &:nth-child(even) {
        background: $fi-color-white-base;
      }
    }

    .event-date {
      background: $fi-color-depth-light2;
      border-bottom: 1px solid $fi-color-depth-light1;
    }

    .event-time {
      padding-top: $fi-spacing-s;
      @include fi-text-body-semi-bold-small;
    }
  }
}
