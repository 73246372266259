$image_base_path: '../../../sevi-style-guide';
$font_base_path: '..';

$breakpoint-2-1: 575px;
$breakpoint-2: 576px;

$breakpoint-3-1: 767px;
$breakpoint-3: 768px;

$breakpoint-4-1: 991px;
$breakpoint-4: 992px;

$breakpoint-6-1: 1199px;
$breakpoint-6: 1200px;


$body-text-size:	16px;
$base-font-size: 16px !default;

$margin:	15px;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Small screen / tablet
$screen-sm:                  $breakpoint-3 !default;
$screen-sm-min: 			$screen-sm !default;

// Medium screen / desktop
$screen-md:                  $breakpoint-4 !default;
$screen-md-min: $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  $breakpoint-6 !default;
$screen-lg-min: $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// This was previously defined in bootstrap-sass. It's not defined in bootstrap v4 and caused a compile error.
$text-color: #333 !default;

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$messages-container-z: 900;
$modal-z-index: 950;
