#developer-page-wrapper {
  @extend #page-wrapper;

  #expand-search-button {
    display: none;
  }
  .search-wrapper {
    width: 100%;
    padding: 10px 15px 10px 15px;
    border-top: solid 1px #235A9A;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    form {
      flex-grow: 1;
      display: block;
    }
    #search-box, #id-main-search-button {
      border: solid 1px white;
      color: white;
      &::placeholder {
        color: white;
      }
      background: #003479;
      &::selection {
        color: #003479;
        background: #fffffffe; // For Safari https://github.com/w3c/csswg-drafts/issues/6853
      }
    }
    @media (min-width: $breakpoint-4) {
      max-width: 400px;
    }
    @media (max-width: $breakpoint-4-1) {
      max-width: 991px;
    }
  }
}

#developer-frontpage-hero-area {
  @extend #frontpage-hero-area;
  border-bottom: 10px solid $kehittajilleTurkoosi;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.19)),
  url(#{$image_base_path}/img/developer_frontpage_hero.webp) center/cover no-repeat;
  background-blend-mode: multiply, normal;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $fi-spacing-xxxl;
    margin-bottom: $fi-spacing-xxxl;
    @media (max-width: $breakpoint-3-1) {
      padding-top: $fi-spacing-xl;
      padding-bottom: $fi-spacing-xxl;
      margin-bottom: 0;
    }
  }

  .icons-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: $fi-spacing-xl;

    svg {
      width: 60px;
      height: 60px;
    }

    @media (max-width: $breakpoint-3-1) {
      display: none;
    }
  }

  .ingress-text {
    display: flex;
    @media(min-width: $breakpoint-3) {
      max-width: 600px;
    }
  }

  @media (max-width: $breakpoint-3-1) {
    .container, #developer-frontpage-hero-area h1 {
      align-items: flex-start;
    }

    .rectangle-divider {
      width: 150px;
    }
  }
}

.developer-frontpage-themes {
  background-color: $fi-color-highlight-light2;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 140px;
  }
  .themes-intro {
    display: flex;
    padding: $fi-spacing-xxl 0;
    flex-direction: column;
    gap: 10px;
    @media(min-width: $breakpoint-3-1) {
      width: 700px;
      align-items: center;
    }
    .content-text {
      display: flex;
    }
  }
  .themes {
    display: flex;
    flex-wrap: wrap;
    gap: $fi-spacing-xl;
    @media(max-width: $breakpoint-6-1) {
      justify-content: center;
    }
    @media(max-width: $breakpoint-3-1) {
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: $breakpoint-3) and (max-width: $breakpoint-4-1) {
      width: 730px
    }
    .theme {
      display: flex;
      padding: $fi-spacing-xl $fi-spacing-xl 0 $fi-spacing-xl;
      flex-direction: column;
      align-items: center;
      border-radius: $fi-spacing-inset-xs;
      background: $fi-color-white-base;
      box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.20);
      width: 100%;
      @media (min-width: $breakpoint-3) {
        width: 540px
      }
    }
    .theme-title {
      display: flex;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      @media(max-width: $breakpoint-3-1) {
        flex-direction: column;
        h3 {
          align-self: flex-start;
        }
      }
      .theme-icon {
        width: 90px;
        height: 90px;
        padding: 5px;
        justify-content: center;
        align-items: center;
      }
      .theme-icon>svg {
        display: flex;
        width: 80px;
        height: 80px;
        justify-content: center;
        align-items: center;
      }
    }
    .theme-lead-paragraph {
      display: flex;
      align-self: flex-start;
      margin-top: $fi-spacing-s;
      margin-bottom: $fi-spacing-m;
    }
    .theme-links-area {
      display: flex;
      width: 100%;
      height: 100%;
      padding: $fi-spacing-m $fi-spacing-xl $fi-spacing-xl $fi-spacing-xl;
      align-items: flex-start;
      gap: 20px;
      border-left: 5px solid $kehittajilleTurkoosi;
      box-sizing: content-box;
      @media (max-width: $breakpoint-3-1) {
        flex-direction: column;
        align-items: center;
      }
    }
    .theme-links-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      @media (min-width: $breakpoint-3) {
        width: 50%;
        &:only-child {
          width: 100%;
        }
      }
      .fi-link {
        font-size: 18px;
      }
    }
  }
}

.developer-frontpage-content-area {
  background-color: $fi-color-highlight-light3;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    padding-top: $fi-spacing-xxl;
    @media (max-width: $breakpoint-3-1) {
      align-items: flex-start;
    }
  }

  .content-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: $fi-spacing-l;
    @media (max-width: $breakpoint-3-1) {
      align-items: flex-start;
      margin-bottom: 0px;
      padding-left: 0px;
      padding-bottom: $fi-spacing-xxl;
      span {
        margin-bottom: 0px;
      }
    }
  }
  .content-heading, .themes-intro {
    max-width: 700px;
  }

  .content-text {
    display: flex;
    max-width: 700px;
  }

  .frontpage-spotlights {
    .row {
      padding-bottom: $fi-spacing-xxl;
    }
    width: 100%;
    .spotlights-centered {
      justify-content: center;
    }
    .fi-link:focus, .fi-button:focus {
      outline: 2px solid #1A97C4;
      outline-offset: 2px;
      box-shadow: none;
    }
  }
}

.developer-language-menu {
  #header-language-menu.fi-language-menu_button {
    background-color: #003479;
    color: #ffffff;
    .fi-icon-base-fill {
      fill: #ffffff;
    }
  }
}

#developer-page-header {
  @extend #page-header;
  border-top: $kehittajilleTurkoosi 4px solid;
  margin-bottom: $header-margin-bottom;
}

.developer-header-row {
  @extend .header-row;
  background-color: $fi-color-brand-base;
  border-bottom: none;

  .container {
    @media (max-width: $breakpoint-4-1) {
      max-width: none;
    }
  }

  .header-language-selection {
    margin-left: 0;
  }

  .header-top-row-content {
    min-height: 52px;
    padding: 0;
  }
  .mobile-menu-toggle-button {
    color: $white;
  }
}

// See also .role-selection
.theme-selection {
  background: #002d69;
  height: 50px;

  .theme-selection-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 1em;
    height: 100%;

    .theme-selection-item {
      margin: 0 30px;
      align-content: end;
    }

    @media(max-width: $breakpoint-6-1) {
      .theme-selection-item {
        margin: 0 20px;
      }
    }

    @media(max-width: $breakpoint-4-1) {
      .theme-selection-item {
        margin: 0 10px;
      }
    }
  }

  .theme-selection-item {
    .dropdown-wrapper {
      position: static;
    }

    &.selected {
      .dropdown-button, .theme-item-link {
        border-bottom-color: $digiDeveloper;
      }
    }

    .dropdown-wrapper.active {
      .dropdown-content {
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
      }
      .dropdown-button-container {
        position: relative;
      }
      .dropdown-button {
        .dropdown-arrow {
          transform: scale(1.5) rotate(180deg);
        }
      }
      .dropdown-button:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border: 16px solid transparent;
        border-top: 0;
        border-bottom: 16px solid #003479;
        z-index: 42;
      }
    }
    a:focus {
      outline: 0;
      border-radius: 2px;
      box-shadow: 0 0 0 2px #1a99c7;
    }
  }

  // Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
  .dropdown-button, .theme-item-link.theme-item-link {
    color: white;
    text-decoration: none;
    background-color: transparent;
    display: inline-block;
    line-height: 1;
    @include rem(padding, 5px);
    @include rem(padding-bottom, 11px);
    @include rem(border-bottom, 4px solid transparent);

    &:hover, &:focus {
      border-bottom-color: $digiDeveloper;
    }
  }

  .dropdown-button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .dropdown-arrow {
      color: white;
      vertical-align: middle;
      transform: scale(1.5)
    }
  }

  .dropdown-content {
    line-height: 2em;
    background: #fff;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.20);

    ul {
      position: static;
      border: none;
      box-shadow: none;
      padding: 0;
      background-color: transparent;

      .arrow-item {
        line-height: 1;
      }

      li {
        a.fi-link {
          text-decoration: underline;
          width: 100%;
          background: transparent;
          line-height: 1.5;
          @include rem(font-size, 18px);
          @include rem(border-left, 4px solid transparent);
          @include rem(margin-left, -5px);
          @include rem(margin-top, 5px);
        }
      }
    }
  }
}

.developer-navigation-header {
  height: 75px;
  display: flex;
  color: white;
  background: #003479;
  justify-content: space-between;
  align-items: center;

  &-title {
    color: white;
    margin-left: 40px;
  }

  &-close {
    color: white;
    background: #003479;
    margin-right: 40px;
  }
}

.developer-navigation-themes {
  width: 990px;
  height: 100%;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;

  &-column {
    padding-left: 40px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      border-left: $depthLight1 solid 1px;
    }
  }
}

.developer-navigation-themes-column > li:not(:first-child) {
  margin-top: 30px;
}

.developer-navigation-theme {
  width: 280px;
}

.developer-mobile-main-navi {
  background: #003479;
  color: white;

  hr {
    margin: 0;
    margin-block-end: 0;
    margin-block-start: 0;
  }

  &-content {
    border-bottom: 1px solid white;
    border-top: 1px solid white;
  }
  .fi-link, .developer-mobile-menu-button-mid, .developer-mobile-menu-button-top {
    color: white;

    &:visited, &:hover, &:focus {
      color: white;
    }
  }

  li {
    padding-left: 15px;
    &.developer-mobile-theme-link.selected, &.current-language {
      border-left: 4px solid $digiDeveloper;
      padding-left: 11px;
      a {
        font-weight: 600;
      }
    }
    .developer-mobile-menu-border-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .border-holder.selected {
        content: '';
        height: 40px;
        border-left: 4px solid $digiDeveloper;
        padding-left: 11px;
        margin-left: -15px;
      }
      button {
        font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
      }
    }

    &.developer-mobile-theme.selected > button {
      font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
    }
  }

  .developer-mobile-menu-root > li {
    padding-right: 15px;
    .developer-mobile-menu-button-top {
      .dropdown-arrow {
        transform: scale(1.5);
      }
    }
    &.open {
      .developer-mobile-menu-button-top {
        .dropdown-arrow {
          transform: scale(1.5) rotate(180deg);
        }
      }
    }
  }

  .developer-mobile-themes-list {
    .dropdown-arrow.open {
      transform: rotate(180deg);
    }
    .dropdown-arrow {
      transform: scale(1.19);
    }
    padding-bottom: 20px;
  }

  button {
    font-family: SourceSansProRegular, SourceSansProSemibold, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
    padding: 0;
  }
  .developer-mobile-menu-button-top, .developer-mobile-menu-button-mid, .developer-mobile-main-navi-frontpage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: #003479;
    width: 100%;
  }
  .developer-mobile-main-navi-frontpage > .fi-link {
    width: 100%
  }
  .developer-mobile-menu-button-top {
    cursor: pointer;
    margin: 10px 0 10px 0;
  }
  .developer-mobile-menu-button-mid, .developer-mobile-main-navi-frontpage {
    margin: 5px 0 5px 0;
  }
  .developer-mobile-menu-button-top, .developer-mobile-main-navi-frontpage {
    height: 47px;
    font-size: 18px;
  }
  .developer-mobile-menu-button-mid {
    cursor: pointer;
    height: 44px;
    font-size: 16px;
  }

  li.developer-mobile-theme-link {
    height: 44px;
    margin: 5px 0 5px 0;
    display: flex;
    align-items: center;
    a.fi-link {
      font-size: 16px;
      width: 100%;
    }
    &:hover {
      border-left: 4px solid $digiDeveloper;
      padding-left: 11px;
    }
    &.selected {
      font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
    }
  }

  .developer-mobile-themes-link-list {
    background: #00275A;
    text-decoration: underline;
    text-decoration-color: $depthLight1;
    padding: 15px 30px 20px 0;
    margin-left: -30px;
    margin-right: -30px;
  }

  .mobile-main-navi-languages {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-main-navi-languages > li {
    a.fi-link {
      display: inline-flex;
      align-items: center;
      height: 30px;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
}

#developer-footer {
  @extend #footer;
}

#main:has(>.developer-frontpage-content-area) + #developer-footer,
#main:has(>#developer-service-frontpage-hero-area) + #developer-footer {
  margin-top: 0;
}

.developer-page-footer {
  @extend .page-footer;
  border-top: $kehittajilleTurkoosi 4px solid;
  background-color: $fi-color-brand-base;
  color: #ffffff;

  li .fi-link.fi-link {
    text-decoration: underline;
    text-decoration-color: #FFFFFF;
    color: #FFFFFF;
  }

  .footer-suomifi-logo {
    margin-right: 0;
  }

  .footer-site-description {
    align-self: flex-start;
    @media (min-width: $breakpoint-4) {
      @include rem(width, 360px);
    }
  }

  .site-description-container {
    display: flex;
    max-width: 1110px;
    padding: 0 0 $fi-spacing-xxl 0;
    margin: 0;
    border-bottom: solid white 1px;
    align-items: center;
    gap: 20px;

    img {
      vertical-align: -webkit-baseline-middle;
    }
  }

  .link-list {
    margin-top: $fi-spacing-xxl;
    li {
      margin-right: 0;
    }
    a {
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
    max-width: 800px;
  }
}

.rectangle-divider {
  width: 250px;
  height: 3px;
  background-color: $kehittajilleTurkoosi;
  margin-bottom: $fi-spacing-xxl;
}

img.developer-logo {
  height: 32px;
}

#developer-service-frontpage-hero-area {
  @extend #developer-frontpage-hero-area;
  color: #FFFFFF;
  border-bottom: 5px solid $kehittajilleTurkoosi;
  background: url(#{$image_base_path}/img/developer_service_frontpage_hero.webp) lightgray 50% / cover no-repeat;
  h1 {
    color: #FFFFFF;
    margin-top: $fi-spacing-xs;
    margin-bottom: $fi-spacing-xl;
  }
  .rectangle-divider.small-rectangle {
    width: 80px;
    height: 5px;
    margin-bottom: $fi-spacing-xl;
  }
  .hero-ingress {
    @media(min-width: $breakpoint-3) {
      width: 650px;
    }
  }
  .beta-chip {
    margin-bottom: 20px;
  }
  @media(max-width: $breakpoint-3-1) {
    .container, #developer-service-frontpage-hero-area {
      align-items: center;
      h1 {
        text-align: center;
      }
    }
    .container {
      padding-top: $fi-spacing-xxl;
      padding-bottom: $fi-spacing-xxl;
    }
    .icons-row {
      display: flex;
    }
  }
}

#developer-service-frontpage-instructions-area {
  background-color: $fi-color-highlight-light3;
  h2, h3, .fi-block {
    max-width: 700px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $fi-spacing-xxl;
  }
  .center-instructions {
    justify-content: center;
  }
  .instruction-highlights {
    margin-bottom: $fi-spacing-xxl;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    ul {
      flex: auto;
      @media(max-width: $breakpoint-4-1) {
        gap: $fi-spacing-m;
      }
      @media(min-width: $breakpoint-4) {
        li {
          margin-bottom: $fi-spacing-m;
        }
      }
    }

    a.fi-link {
      display: block;
      padding: $fi-spacing-xxl 0;

      &:hover, &:active {
        text-decoration: none;
      }
      @media(min-width: $breakpoint-4) {
        height: 100%;
      }
    }
  }
  .instruction-highlight {
    border-radius: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);
    background-color: $fi-color-white-base;
    li, .arrow-item {
      text-align: center;
    }
    .arrow-item {
      margin-left: 25px;
      margin-right: 20px;
    }
    .fi-block {
      display: flex;
      justify-content: center;
      .fi-text {
        text-align: center;
        font-size: 22px;
      }
    }
    @media(min-width: $breakpoint-4) {
      height: 100%;
      transform: scale(1);
      transition: transform ease-in-out 0.1s;
      &:hover {
        transform: scale(1.03, 1.013);
      }
    }
    .highlight-icon {
      display: flex;
      justify-content: center;
    }
  }
  .instruction-links {
    margin-bottom: $fi-spacing-xxl;
    @media(max-width: $breakpoint-3-1) {
      align-self: flex-start;
    }
  }
  .secondary-highlights {
    margin-bottom: $fi-spacing-xxxl;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    .link-box-content {
      height: 100%;
    }
  }
}

#developer-help-section {
  background-color: $fi-color-depth-secondary-dark1;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $fi-spacing-xxl 0;
    align-self: stretch;
    @media(max-width: $breakpoint-3-1) {
      padding: $fi-spacing-xxl $fi-spacing-s;
      align-items: flex-start;
    }
    @media(min-width: $breakpoint-4) {
      width: 940px;
    }
  }

  .help-box-area {
    display: flex;
    gap: $fi-spacing-xl;
    align-self: stretch;
    margin-bottom: $fi-spacing-xxxl;
    justify-content: center;
    @media(max-width: $breakpoint-3-1) {
      flex-direction: column;
      align-self: stretch;
    }
  }

  .help-box {
    display: flex;
    flex-direction: column;
    padding: 10px 20px var(--s, 15px) var(--xl, 30px);
    align-items: flex-start;
    flex: 0 0 50%;
    background-color: $fi-color-white-base;
    border-left: $kehittajilleTurkoosi 5px solid;
    border-radius: 4px;
    box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.20);
  }

  .help-item:not(:last-child) {
    margin-bottom: $fi-spacing-m;
  }

  + .siha-feedback-form-container .active-form-container {
    margin-top: 0;
    padding: $fi-spacing-xxl;
    border: 0;
  }
}

#developer-service-frontpage-feedback-area {
  background-color: $fi-color-white-base;
  padding: $fi-spacing-xxl 0;
  border-top: $kehittajilleTurkoosi 1px solid;

  .siha-feedback-form-container .active-form-container {
    margin-top: 0;
    border: 0;
  }
}

.developer-service-frontpage-introduction-section {
  background-color: $fi-color-highlight-light2;
  .container {
    display: flex;
    padding: $fi-spacing-xxl 165px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media(max-width: $breakpoint-4-1) {
      padding: $fi-spacing-xxl $fi-spacing-s;
    }
  }
}


.developer-service-frontpage-recommendation-section {
  background-color: $fi-color-highlight-light4;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    padding-bottom: $fi-spacing-xxxl;
  }
  .quotemark {
    color: $fi-color-depth-base;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 120px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px;
    width: 100%;
    padding-top: $fi-spacing-xxl;
  }
  .recommendations {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    justify-content: center;
    .recommendation-list {
      justify-content: center;
      display: flex;
      align-items: flex-start;
      gap: $fi-spacing-xl;
      @media(max-width: $breakpoint-3-1) {
        flex-direction: column;
      }
    }
    .recommendation-list-item {
      flex: 1 0 0;
      padding: 0 $fi-spacing-l;
    }
  }
  .quotes-centered {
    justify-content: center;
  }
  .quote-box {
    display: flex;
    padding: $fi-spacing-m $fi-spacing-s $fi-spacing-xl $fi-spacing-s;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;

  }
  .quote {
    display: flex;
    padding: $fi-spacing-s $fi-spacing-xl;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    align-self: stretch;
    border-left: 5px solid $fi-color-depth-dark2;
    .fi-text {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }
}

.developer-service-frontpage-spotlight-section {
  background-color: $fi-color-highlight-light2;
  .container {
    display: flex;
    padding: $fi-spacing-xxl 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media(max-width: $breakpoint-3-1) {
      padding: $fi-spacing-xxl $fi-spacing-l $fi-spacing-xxl $fi-spacing-s;
      align-items: flex-start;
    }
  }
  .spotlights-lead-paragraph {
    @media(min-width: $breakpoint-3-1) {
      max-width: 700px;
    }
  }
  .frontpage-spotlights {
       margin: $fi-spacing-xxs;
       width: 100%;
       .spotlights-centered {
         justify-content: center;
       }
       .fi-link:focus, .fi-button:focus {
         outline: 2px solid #1A97C4;
         outline-offset: 2px;
         box-shadow: none;
       }
     }
}

.developer-announcements-section {
  background-color: $fi-color-white-base;
  .container {
    display: flex;
    padding: $fi-spacing-xxl 0 $fi-spacing-xxxl 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    @media(max-width: $breakpoint-3-1) {
      padding: $fi-spacing-xl $fi-spacing-s $fi-spacing-xxl $fi-spacing-s;
      align-items: flex-start;
    }
  }
  .announcement-row {
    display: flex;
    flex-direction: row;
    @media(max-width: $breakpoint-3-1) {
      flex-direction: column;
      align-self: stretch;
    }
  }
  .developer-announcement-item {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    a.fi-link {
      &:not(:visited) {
        color: $fi-color-highlight-base;
      }
      font-weight: 600;
    }
    &:not(:first-child) {
      border-left: 1px solid $fi-color-depth-base;
      @media(max-width: $breakpoint-3-1) {
        border-left: none;
        border-top: 1px solid $fi-color-depth-base;
      }
    }
    @media(max-width: $breakpoint-3-1) {
      padding: 20px 0;
    }
  }
}

.developer-announcement-view, .developer-announcements-section {
  .announcement-chips {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 5px;
  }
  .announcement-chip {
    background-color: $fi-color-highlight-light3;
    color: $fi-color-depth-dark1;
    font-size: 14px;
    font-weight:400;
    @media(max-width: $breakpoint-3-1) {
      display: none;
    }
    &.incident-chip {
      @media(max-width: $breakpoint-3-1) {
        display: block;
        background-color: $fi-color-black-base;
        color: $kehittajilleTurkoosi;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
