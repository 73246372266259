%default-focus-active {
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $fi-color-accent-secondary;
  }
  &:active {
    outline: 0;
  }
}

// Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
.button-link.button-link {
  letter-spacing: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  min-height: 40px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: rgb(0, 53, 122) 0 1px 1px;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 20px;
  background: linear-gradient(0deg, rgb(35, 90, 154) 0%, rgb(42, 110, 187) 100%);
  border-radius: 2px;
  text-size-adjust: 100%;
  text-transform: none;
  box-sizing: border-box;
  vertical-align: baseline;
  display: inline-block;
  max-width: 100%;
  overflow: visible;
  margin: 0;
  border: none;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;

  .fi-icon.button-icon {
    color: inherit;
  }

  &.button-link-inverted {
    background: hsl(212,63%,45%);
    border: 1px solid hsl(0,0%,100%);
    text-shadow: none;
  }

  &[disabled] {
    text-shadow: rgb(41, 41, 41) 0 1px 1px;
    user-select: none;
    background: linear-gradient(0deg, rgb(165, 172, 177) 0%, rgb(200, 205, 208) 100%);
  }

  &:hover {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(42, 110, 187) 0%, rgb(46, 120, 204) 100%);
  }

  &:active {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background: rgb(35, 90, 154);
  }

  &:focus {
    color: rgb(255, 255, 255);
  }

  &:visited {
    text-decoration: none;
    color: rgb(255, 255, 255);
  }

  &.button-link-secondary {
    color: rgb(42, 110, 187);
    text-shadow: none;
    background: none rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(42, 110, 187);
    border-image: initial;

    &[disabled] {
      color: rgb(165, 172, 177);
      text-shadow: none;
      background: none rgb(247, 250, 253);
      border-color: rgb(165, 172, 177);
    }

    &:hover {
      background: linear-gradient(0deg, rgb(236, 237, 238) 0%, rgb(255, 255, 255) 100%);
      text-decoration: none;
      color: rgb(42, 110, 187);
    }

    &:active {
      background: none rgb(236, 237, 238);
      text-decoration: none;
      color: rgb(42, 110, 187);
    }

    &:visited {
      text-decoration: none;
      color: rgb(42, 110, 187);
    }

    &:focus {
      color: rgb(42, 110, 187);
    }
  }
}

.old-button {
  background: $lake;
  @include rem(line-height, 18px);
  @include rem(font-size, 18px);
  font-family: SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
  color: #fff;
  letter-spacing: 0;
  text-decoration: none;
  margin: 0;
  border: 0;
  @include rem(padding, 10px 20px);
  border-radius: 2px;
  font-weight: 400;
  cursor: pointer;

  &.icon {
    background: transparent;
  }

  &:visited {
    color: #fff;
  }

  &:hover {
    background: lighten($lake, 10%);
  }

  @extend %default-focus-active;

  &[disabled] {
    background: $gray-60;
  	cursor: not-allowed;
    .button-icon {
      fill: $gray-k20;
    }
  }
}

// NOTE: also vare-ui uses this class and removing this may break styles there.
.link-button {
  cursor: pointer;
  color: $lake !important;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 100%;
  font: inherit;
  &:hover {
    text-decoration: underline;
    background: none;
  }
  &:focus {
    outline: 0;
    border-radius: 2px;
    box-shadow: 0 0 0 2px $fi-color-accent-secondary;
  }
}

.fi-button {
  .fi-icon {
    color: inherit;
  }
}
