.support-channels {
    background: #fff;
    overflow: hidden;
    clear: both;
    border-top: 1px solid #c8cdd0;
    @include rem(font-size, 16px);
    @include rem(line-height, $fi-spacing-m);
    @include rem(padding-bottom, $fi-spacing-xxxl);
    @include rem(padding-top, $fi-spacing-xl);

    .support-channel-row {
        display: block;
        @include rem(margin-bottom, $fi-spacing-xxs);
    }

    @media (max-width: $breakpoint-3-1) {
        @include rem(padding-bottom, $fi-spacing-xl);
    }

    @media (min-width: 992px) {
        h3 {
            @include rem(margin-top, $fi-spacing-xs);
        }
    }
}
