.map-container {
  //
  // Desktop
  //
  .map-sidepanel-container .service-locations {
    @include rem(padding-top, 15px);
    padding: 15px 10px 0 10px;
    overflow-x: visible;
    border: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .document-title h1 {
      padding-bottom: 10px;
      padding-left: 10px;
      margin: 0;
      @extend .visual-h4;
      @extend .semibold;
      ::after {
        content: ":";
      }
    }
    .pagetitle {
      display: block;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    .service-location-list-container {
      flex: 1;
      height: 100%;
    }

    .service-location-list {
      scrollbar-width: none;
    }
    .service-location-list::-webkit-scrollbar {
      display: none;
    }

    .service-locations-list-item-wrapper {
      @include rem(margin-bottom, 15px);
      border-top: 1px solid $gray-60;
      border-bottom: none;
      cursor: pointer;
      margin: 0;

      &:last-of-type {
        border-bottom: 1px solid $gray-60;
      }

      .service-locations-list-item {
        margin-left: 0;
        padding: 15px 10px;

        h3 {
          @include rem(font-size, 20px);
          @include rem(line-height, 26px);
          letter-spacing: -0.1px;
          color: $link-color;
          margin: 0 0 15px;
        }
        p {
          margin: 0;
        }

        .no-coordinates {
          margin-top: 10px;
        }
      }

      &:hover, &.selected, &:focus {
        border-top: 2px solid $color-persimmon;
        border-bottom: 2px solid $color-persimmon;

        & + .service-locations-list-item-wrapper {
          border-top: none;
          padding-top: 1px;
        }

        .service-locations-list-item {
          border-left: none;
          background-color: #fff;
          padding: 14px 10px 13px 10px;

          h3 {
            color: $color-persimmon;

            svg {
              fill: $color-persimmon;
            }
          }
        }
      }
    }
  }

  //
  // Mobile
  //
  .map-sidepanel-container-mobile {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .service-location-listing-header {
      background: #fafafa;
      padding: 15px;

      button {
        width: 100%;
        @include rem(font-size, 14px);
      }

      .button-icon {
        margin-left: 8px;
      }
    }

    .service-locations {
      border-top: none;
      padding: 0;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;

      .service-location-list-container {
        flex: 1;
      }

      .service-locations-list-item-wrapper {
        margin: 0;
        border-top: 1px solid #ced2d5;
        border-bottom: none;

        &:last-of-type {
          border-bottom: 1px solid #ced2d5;
        }

        .service-locations-list-item {
          margin: 0;
          padding: 15px;
        }

        &:hover, &.selected {
          border-top: 2px solid $color-persimmon;
          border-bottom: 2px solid $color-persimmon;

          & + .service-locations-list-item-wrapper {
            border-top: none;
            padding-top: 1px;
          }

          .service-locations-list-item {
            border-left: none;
            background-color: #fff;
            padding: 14px 15px 13px 15px;

            h3 {
              color: $color-persimmon;

              svg {
                fill: $color-persimmon;
              }
            }
          }
        }
      }
    }
  }

  //
  // Common styles for item title
  //
  .service-locations .service-locations-list-item-wrapper {
    .service-locations-list-item {
      h3 {
        position: relative;
        color: $link-color;
        padding-right: 30px;
        margin-top: 0;
        // Following is a workaround for some cases where long words cause element to stretch even though
        // word-wrapping is used.
        display: table;
        table-layout: fixed;
        width: 100%;

        .inline-icon.fi-icon {
          position: absolute;
          top: 0;
          right: 0;

          color: inherit;
          max-width: 25px;
          max-height: 25px;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .no-results {
    padding: 10px;
  }
}

.map-container.embedded-map-container {
  height: 525px;
  width: 100%;
}

.open-map-link.open-map-link {
  margin: 20px;
  svg {
    margin-bottom: -2px;
    margin-right: 5px;
  }
}

.open-map-link-for-channel.open-map-link-for-channel {
  margin: 15px 0 0 -7px;
  svg {
    margin-bottom: -2px;
    margin-right: 2px;
  }
}
