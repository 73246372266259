@import "colors";

:root {
  // width of the fade overlay
  --siha-table-fade-mask-size: 30px;
}

.siha-code-block {
  font-family: "SourceCodeProRegular";
  background-color: $fi-color-depth-light2;
  padding: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  // Hack for the contents where people have managed to put <code> inside <pre> before it was prevented
  .siha-code-block {
    padding: 0;
  }
}

.siha-file-label {
  vertical-align: top;
  padding-left: 10px;
}

.siha-table-container {
  overflow-x: auto;

  // mask image overlay
  // ==========================
  mask-image:
  linear-gradient(
    90deg,
    transparent 0%,
    black var(--siha-table-fade-mask-size),
    black calc(100% - var(--siha-table-fade-mask-size)),
    transparent 100%
  ),
  // account for scrollbar area
  linear-gradient(to top, black 20px, transparent 5px);
 }

.siha-table {
  margin-left: var(--siha-table-fade-mask-size);
  margin-right: var(--siha-table-fade-mask-size);
  width: calc(100% - 2*var(--siha-table-fade-mask-size));

  tr:first-child {
    th {
      border-bottom: 2px solid $highlightBase;
    }
  }

  tr:nth-child(odd) {
    td {
      background-color: $depthLight3;
    }
  }

  th {
    min-width: 150px;
    border: 1px solid $depthLight1;
    padding: 10px 15px;
    p {
      font-family: SourceSansProBold, SourceSansProRegular, 'Helvetica Neue', Arial, helvetica, sans-serif, clean !important;
      font-weight: 400 !important;
      margin-bottom: 0 !important;
    }
  }

  td {
    min-width: 150px;
    border: 1px solid $depthLight1;
    padding: 10px 15px;

    word-break: normal;

    p {
      margin-bottom: 0 !important;
    }
  }
}
