.sote-comparison-select-location {
  background-color: #eaf2fa;
  position: relative;
  @media (min-width: $breakpoint-3) {
    padding-right: 60px;
  }

  &.sote-comparison-select-location-triangle:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 14px white;
    border-left: solid 14px transparent;
    border-right: solid 14px transparent;
  }

  &.border-right {
    border-right-color: lightgray;
    border-right-width: 2px;
    @media (min-width: $breakpoint-3) {
      border-style: solid;
    }
  }
}

.sote-service-location {

  @media (max-width: $breakpoint-3) {
    overflow-wrap: break-word;
  }

  .sote-organization {
    color: $gray-k40;
    @include rem(font-size, 16px);
    @include rem(line-height, 20px);
    font-family: SourceSansProRegular, Arial, helvetica, sans-serif, clean;
  }

  h2 {
    margin-bottom: 20px;
    margin-top: 5px;
    @include rem(font-size, 20px);
    @include rem(line-height, 26px);
  }

  h3 {
    margin-bottom: 10px;
    margin-top: 0px;
    @include rem(font-size, 18px);
    @include rem(line-height, 26px);
  }

  .sote-services-heading-2 {
    font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial;
  }

  .fi-icon {
    margin-bottom: -2px;
  }

  .right-arrow-icon {
    margin-left: 5px;
  }

  .sote-selection-criteria {
    background-color: #F8F8F8;
    border-color: lightgray;
    border-style: dashed;
    border-width: 2px;
    text-align: center;
    padding: 12px;
    margin: 0;
  }

  .no-data-availailable {
    background-color: #F8F8F8;
    font-style: italic;
    padding: 12px;
    margin: 0;
  }

  .label {
    font-weight: 600;
  }
}

.sote-service-location-sticky-header {
  background-color: $color-body-background;
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  padding: 5px;
  box-shadow: 0 0 11px;

  @media (max-width: $breakpoint-3) {
    display: flex;
  }

  .sote-service-location-sticky-header-piece {
    width: 50%;
    padding: 15px;
  }
}


.sote-service-location-map-container {

  $map-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  $button-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  $map-radius: 2px;

  $offset-bottom: 10px;
  $offset-right: 20px;

  display: flex;
  position: relative;
  width: 100%;
  flex: 1 0;

  &.full-screen {
    @media (max-width: $breakpoint-3-1) {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: 12px solid gray;
    }
    @media (max-width: $breakpoint-3) {
      #oskari-map {
        pointer-events: auto;
        border: none;
      }
    }
  }

  #oskari-map {
    border: solid darkgray 2px;
    @media (min-width: $breakpoint-3-1) {
      width: 520px;
      height: 300px;
    }
    @media (max-width: $breakpoint-3) {
      width: 100%;
    }
    pointer-events: none;
  }

  .full-screen-header {
    z-index: 999;
    display: inline-block;
    position: absolute;
    background-color: #FFFFFF;
    left: 0;
    width: 100%;
    padding: 15px 0px 20px 20px;

    div {

      &.title {
        padding-top: 5px;
        font-weight: 600;
      }

      &.right {
        position: absolute;
        right: $offset-right;
        padding-right: 0;
        text-align: right;
      }
    }

    button {

      position: absolute;
      display: flex;
      right: 0px;
      border: none;
      padding: 10px 0px;

      color: $color-gray-2;

      span.close-text {
        padding-top: 0px;
        padding-right: 10px;
        text-transform: uppercase;
        font-size: 16px;
      }

      background: #fff;

      &:hover {
        background: #fff;
      }

      svg.button-icon {
        max-height: 20px;
        max-width: 20px;
        fill: $color-gray-1;
      }
    }
  }

  .map-button-container {

    display: inline-block;
    position: absolute;
    padding: 4px;

    &.right {
      right: $offset-right;
    }

    &.bottom {
      bottom: $offset-bottom;
    }

    button.map-button {
      background-color: #fff;
      box-shadow: $button-shadow;
      border-radius: $map-radius;
      color: $lake;
      margin-bottom: 10px;
    }

    button.zoom-in, button.zoom-out, button.rounded-circle {
      position: relative; // for z-index
      height: 40px;
      width: 40px;
      padding: 0;
      z-index: 4;

      svg {
        margin-top: 2px;
        height: 20px;
        width: 20px;
        fill: $lake;
      }

    }
  }

}
