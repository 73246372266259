.mandate-application-landing-page {
  p {
    @include rem(margin-bottom, 20px);
  }

  div.links {
    margin-top: 15px;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 15px;
    }
    .arrow-item {
      @include rem(font-size, 18px);
    }
    .file-icon {
      @include rem(height, 24px);
      @include rem(width, 24px);
      @include rem(margin-right, 10px);
      vertical-align: middle;
    }
  }

  .mandate-applications-list-box {
    margin-bottom: 20px;
  }

  .mandate-applications-list {
    border: none;
    margin-bottom: 20px;
    height: fit-content;

    .mandate-application-list-link {
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;

      .mandate-application-list-link-label {
        color: $text-color;
      }

      &:hover, &:active {
        .mandate-application-list-link-register-number {
          text-decoration: underline;
        }
      }
    }

    @media (min-width: $screen-sm-min) {
      margin-bottom: 30px;
      border: 1px solid $fi-color-depth-light1;
    }

    .state {
      font-family: SourceSansProSemiBold, 'Helvetica Neue', Arial;
      color: #4c4b4b;

      &.waiting-author-action:before {
        background-color: #e97025;
        border-radius: 50%;
        content: "";
        display: inline-block;
        @include rem(margin-right, 6px);
        @include rem(margin-bottom, 2px);
        @include rem(height, 8px);
        @include rem(width, 8px);
      }
    }

    @media (min-width: $screen-sm-min) {
      tr {
        border-left: none !important;
        border-right: none !important;

        > .mandate-applications-list-column-state {
          min-width: 175px;
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .related-content {
      .button-link {
        width: 100%;
      }
    }
  }
}

table.mandate-application {

  width: 100%;

  tr {
    background-color: white;
    border-left: #c8cdd0 1px solid;
    border-right: #c8cdd0 1px solid;
  }

  th, td {
    padding: 10px
  }

  tbody {
    tr:nth-of-type(even) {
      background: #fafafa;
    }
    tr {
      border-bottom: #c8cdd0 1px solid;
    }
  }

  th {
    @include rem(font-size, 14px);
    font-family: SourceSansProSemiBold, 'Helvetica Neue', Arial;
    text-align: left;
  }

  td {
    @include rem(font-size, 16px);
    font-family: SourceSansProRegular, 'Helvetica Neue', Arial;
  }

  @media (max-width: $screen-xs-max) {
    border-spacing: 0 10px;
    border-collapse: separate;

    th, td {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tbody {
      tr {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        border-top: #c8cdd0 1px solid;

        td {
          &:first-child {
            border-top: #c8cdd0 1px solid;
          }
          &:last-child {
            border-bottom: #c8cdd0 1px solid;
          }
          border-left: #c8cdd0 1px solid;
          border-right: #c8cdd0 1px solid;
          &:not(:last-child) {
            padding-bottom: 0 !important;
          }
          padding-left: 20px !important;
          padding-right: 20px !important;

          label {
            display: block;
            @include rem(font-size, 14px);
            font-family: SourceSansProSemiBold, 'Helvetica Neue', Arial;
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    tr > :first-child {
      padding-left: 20px;
    }

    tr > :last-child {
      padding-right: 20px;
    }

    thead {
      border-top: #c8cdd0 1px solid;
      border-bottom: $lake 1px solid;
    }

    td label {
      display: none;
    }
  }
}

.mandate-application-list-filters-container {
  .mandate-application-list-heading {
    margin-top: $fi-spacing-m;
    @media(min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }

  &.mandate-application-list-filters-hidden {
    .mandate-application-list-filters {
      display: none;
    }
  }

  .mandate-application-list-filters {
    background: $fi-color-depth-light3;
    padding: $fi-spacing-m;
    margin: $fi-spacing-m 0;

    .mandate-application-list-filters-button {
      width: 100%;
      @media(min-width: $screen-md-min) {
        width: auto;
      }

      &:not(:last-child) {
        margin: 0 0 $fi-spacing-xs 0;
        @media(min-width: $screen-md-min) {
          margin: 0 $fi-spacing-xs 0 0;
        }
      }
    }
  }

  @media(min-width: $screen-sm-min) {
    display: flex;
    margin: $fi-spacing-m 0;
    &.mandate-application-list-filters-visible {
      flex-direction: column;
    }
    &.mandate-application-list-filters-hidden {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .mandate-application-list-filters-toggle {
      align-self: flex-end;
    }
  }
}

.mandate-application-filter-chips {
  .fi-chip {
    margin-right: $fi-spacing-xs;
    margin-bottom: $fi-spacing-xs;
  }
}

.mandate-application-details-state {
  text-transform: uppercase;
  display: inline-flex;
  margin-bottom: 15px;
  margin-top: 5px;
  svg {
    margin-right: 8px;
    height: 24px;
    width: 24px;
  }
}

.mandate-application-details {

  .details-section {
    margin-top: 40px;
  }

  .actions {
    display: inline-flex;
    > * {
      margin-right: 15px;
    }
    @media(max-width: $breakpoint-4-1) {
      > * {
        margin-bottom: 5px;
      }
      display: grid;
    }
  }

  .state {
    font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-flex;
    margin-bottom: 15px;
    margin-top: 5px;
    &.highlight-state {
      color: darkorange;
      svg {
        margin-right: 8px;
        fill: darkorange;
        max-height: 24px;
        max-width: 24px;
      }
    }
  }


  ul.show-bullets {
    list-style: disc;
    margin-left: 10px;
    padding-left: 10px;
  }

  h1 {
    margin-top: 20px;
  }

  dd {
    margin-bottom: 15px;
  }

  dt {
    font-weight: bold;
  }

  div.attachments {
    .file-icon svg {
      margin-top: 6px;
      max-height: 24px;

      width: 100%;
      height: auto;
    }

    .dropzone {
      border: 1px dashed black;
      background-color: #f7fafd;
    }

    .unlinked-attachment {
      padding: 10px;
      background-color: #f7f7f8;
      border: 1px solid #c8cdd0;
      color: $color-basic-text;
      margin-bottom: 10px;
      font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
      .size {
        font-family: SourceSansProRegular, 'Helvetica Neue', Arial;
        font-size: 16px;
        color: #5f686d;
      }

      .info {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .button-delete-attachment {
        background-color: inherit;
        span {
          font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
          font-size: 14px;
          color: $lake;
        }
        svg {
          max-width: 16px;
          max-height: 16px;
          fill: $lake;
          margin-right: 6px;
        }
      }
    }
  }

  .attachment-error {
    color: darkred;
    font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
    font-size: 14px;
  }
}

.mandate-application-dashed-box {
  background: $fi-color-depth-light3;
  border: 1px dashed $fi-color-depth-base;
  font-style: italic;
  margin: 0;
  padding: $fi-spacing-m;
  @include rem(font-size, 16px);
}

.mandates-accordion-content {
  .specifiers-header {
    @include rem(font-size, 16px);
    font-family: SourceSansProSemiBold, 'Helvetica Neue', Arial;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 10px;
  }
}

.navigation-to-e-authorizations-container-hidden {
  display: none;
}
.navigation-to-e-authorizations-container {
  background-color: #FFFFFF;
  margin-bottom: 25px;
  border-bottom: 1px solid #C9CDCF;

  h2 {
    margin-bottom: 40px;
  }

  .navigation-to-e-authorizations-role-select-container {
    margin-bottom: 40px;

    .e-authorizations-role-select-row {
      @media (min-width: $breakpoint-4) {
        display: flex;
      }
      margin-top: 20px;
      align-items: center;

      button {
        width: 260px;
        // Mobile
        @media (max-width: $breakpoint-4) {
          width: 100%;
        }
      }

      .e-authorizations-role-select-row-button-description-text {
        // Desktop
        @media (min-width: $breakpoint-4) {
          margin-left: 20px;
        }
        // Mobile
        @media (max-width: $breakpoint-4) {
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }

}

.e-authorizations-view {
  padding: 20px 35px;

  @media (min-width: $breakpoint-4) {
    padding: 0 0 0 35px;
  }

  .e-authorizations-instructions-mobile {
    @media (min-width: $breakpoint-4) {
      display: none;
    }
    padding: 20px 0px 0px 20px;
    background: $lake-extralight;
    padding-bottom: 20px;
  }

  .e-authorizations-authorize-with-help-of-officer {
    margin-bottom: 50px;
  }
}
.e-authorizations-instructions-desktop {
  @media (max-width: $breakpoint-4-1) {
    display: none;
  }
  height: 100%;
  padding: 50px 20px 0px 20px;
  background: $lake-extralight;
}

.mandate-application-consent-signer-status {
  svg {
    margin-right: $fi-spacing-xs;
  }
}

.mandate-application-consent-signer-not-shown {
  padding: $fi-spacing-s $fi-spacing-m;
  border: 1px solid $fi-color-depth-light1;
  border-top: none;
  background: $fi-color-depth-light3;
  font-style: italic;
  @include rem(font-size, 16px);
}

.mandate-application-toggle-signers-lock {
  border: 1px solid $fi-color-depth-light1;
  border-left: 4px solid $fi-color-highlight-base;
  padding: $fi-spacing-l;
  margin-top: $fi-spacing-xl;
  margin-bottom: $fi-spacing-xl;
}
