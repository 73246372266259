// .establish-company
$wizard-font-regular: SourceSansProRegular, 'Helvetica Neue', Arial, helvetica, sans-serif, clean;
$wizard-font-semi-bold: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, helvetica, sans-serif, clean;

//column styles
.establish-company-wizard .wizard-step-content {
  min-height: 100px;
}

.wizard-step-content .row {
  margin-bottom: 0px;
}

.wizard-content {
  border: 1px solid #ced2d5;
  background: white;
  padding: 30px 15px;
}

.container.establish-company-wizard .container {
  padding: 0;
  width: 100%;
}

//text elements

h2.wizard-label {
  margin-top: 0px;
}

.container.establish-company-wizard h1 {
  margin-top: 10px;
}

.wizard-step-content p, .container.establish-company-wizard p, .container.establish-company-wizard a:not(.mandate-services-service-link) {
  font-size: 16px;
}

.wizard-step-content h3 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.wizard-step-content h3.h4 {
  font-size: 18px;
  line-height: 1.3em;
  @media all and (min-width: 768px) {
    font-size: 20px;
  }
}

.wizard-step-content .row:nth-child(2) h3 {
  margin-top: 23px;
}

.wizard-step-content p + h3 {
  margin-top: 20px;
}

.wizard-step-content .page-element-content {
  font-size: 16px;
}

// form styles
.wizard-buttons {
  margin-top: 30px;
}

.establish-company-wizard .wizard-label {
  margin-right: 5px;
}

.wizard-step-content input[type=text] {
  max-width: 461px !important;
}

.wizard-step-content .link-button {
  color: $lake !important;
}

.Select-clear {
  display: inline-block;
  font-size: 24px;
  padding-top: 2px;
  padding-right: 10px;
}

.wizard-bulleted-list, .wizard-numbered-list {
  padding-left: 30px;
  font-size: 16px;

  > li {
    margin-bottom: 0.2em;
  }
}

.wizard-bulleted-list {
  list-style-type: disc;
}

.wizard-numbered-list {
  list-style-type: decimal;
  margin-bottom: 1.0em;
}


//list elements
.establish-company-wizard {
  .subnav {
    padding: 15px 15px 15px 20px;

    @media (max-width: $breakpoint-4-1) {
      border: 1px solid $lake-lightest;
    }

    #nav-label {
      text-transform: none;
    }
  }

  .explanatory-note-heading {
    font-weight: 600;
  }

  .explanatory-note-list {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .child-list {
    margin-top: 0;
  }

  .industry-search {
    background: $gray-10;
    padding: 10px 20px 20px 20px;

    .search-box-row {
      display: flex;
      .search-box {
        flex: 1;
        margin-right: 10px;
      }
      input[type=text] {
        max-width: 100% !important;
      }
    }
  }

  .industry-class-chip {
    margin: 5px;
    svg {
      color: white;
    }
  }

  .industry-class-select {
    @media (min-width: $breakpoint-4-1) {
      margin-top: -3px;
    }
  }

  .industry-class-expander-title {
    margin-left: 25px;
    padding-right: 30px;
    font-weight: 500;
    color: $color-basic-text;

    @media (max-width: $breakpoint-4-1) {
      margin-left: 40px;
    }
  }
}

//grey boxes

.establish-company-wizard .checkbox-with-info2 {
  background-color: #F5F5F5;
  border: 1px solid #D9D9D9;
  padding: 15px;
  margin-top: 15px;
}

.establish-company-wizard .checkbox-with-info2 h4 {
  margin-top: 0;
}

.establish-company-wizard .checkbox-with-info2 h4 + p {
  margin-left: 0;
  margin-top: 0;
}

.establish-company-wizard .checkbox-with-info p, .establish-company-wizard .checkbox-with-info2 p {
  margin-top: -0.8em;
}

@media all and (max-width: 767px) {
  .wizard-step-content h3 {
    margin-top: 20px;
  }
  .wizard-step-content .row:nth-child(2) h3 {
    margin-top: 3px;
  }
  .wizard-step-content p + h3 {
    margin-top: 0px
  }
}

/*progress bar*/
#progress-bar {
  font-family: SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
  padding: 0;
  margin: 20px 0 15px 0;

  li {
    .step {
      display: flex;
      text-decoration: none;
      margin-bottom: -6px;
    }

    &.active {
      .progress-bar-pagename {
        font-weight: 600;
        color: $color-dark-gray
      }
    }

    &.disabled {

      .number {
        border-color: $gray-60;
        color: $gray-60;
        background: #fff;
      }

      .progress-bar-pagename {
        color: $color-dark-gray;
      }
    }

    &:not(:last-child):after {
      content: "|";
      margin-left: 11px;
      font-size: x-small;
      color: $gray;
    }
  }

  .number {
    display: inline-block;
    background: $fi-color-success-base;
    border: 1px solid $fi-color-success-base;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    height: 24px;
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    line-height: 22px;
    text-align: center;
    align-self: flex-start;
  }

  .progress-bar-pagename {
    margin-left: 13px;
    font-size: 16px;
    color: $lake;
  }
}

.badge-wizard {
  float: right;
  padding-left: 10px;
}

.wizard-summary-expander {
  font-size: 16px;

  .company-form-list-title {
    font-size: 16px;
    font-family: $wizard-font-semi-bold;
    text-transform: uppercase;
  }

  @media all and (max-width: 991px) {
    .company-form-list-title {
      margin-bottom: 0;
      margin-top: 2em;
    }
    .row:first-child p.company-form-list-title {
      margin-top: 0;
    }
  }
}

.show-permits {
  .selected-filters {
    font-size: 16px;
  }

  .info-text-area {
    margin-top: 20px;
  }

  & > hr {
    margin-top: 20px;
  }

  .permits-list {
    h3 {
      margin: 30px 0;
    }

    .permit-group > h3, .permit-group > h4 {
      margin: 0;

      &.no-results {
        position: initial;
        margin-bottom: 20px;
      }
    }

    .permit-group:not(:first-child) {
      margin-top: 40px;
    }

    a {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }

    hr {
      margin: 40px 0 30px;

      &:last-child {
        margin: 60px 0 0;
      }
    }

    .permits-list-item {
      margin: 40px 0;

      &:first-child {
        margin-top: 28px;
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}

/*print styles*/
@media print {
  #progress-bar,
  .hide-when-printing,
  .wizard-buttons button {
    display: none;
  }

  .establish-company-wizard .box {
    border: none;
  }

  .establish-company-wizard .row {
    display: block;
  }
}

.mandate-application-wizard-search-filters {
  background: $color-gray-10;
  border: 1px solid $color-gray-8;

  > * {
    padding: 20px;
  }
}

.mandate-counts {
  border-top: 1px solid $color-gray-8;
}

.mandate-application-wizard-delegates {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);

  tr:not(:last-child) {
    border-bottom: 1px solid $gray-60;
  }

  td {
    padding: 20px;
  }

  .delegate-id {
    color: $gray-k40;
    font-size: 16px;
  }
}

.mandate-application-wizard-specifier-expander-content {
  font-size: 16px;
  svg {
    margin-right: 5px;
  }
}

.mandate-application-wizard-specifier-descriptions {
  dd {
    margin-bottom: 15px;
  }

  dt {
    font-weight: bold;
  }
}

.mandate-application-wizard-remove-specifier {
  padding: 15px;
}

.mandate-application-wizard-mandate-type-description {
  border-top: 1px solid $gray-60;
  border-bottom: 1px solid $gray-60;
  padding: 30px 0;
  dt {
    font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
  }
  dd {
    color: $depthDark1;
  }
}

.mandate-application-wizard-use-mandate-specifiers {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  background: $gray-5;
  border: 1px solid $gray-60;
}

.mandate-application-wizard-use-mandate-specifiers-switch {
  margin-right: 10px;
}

.mandate-application-wizard-mandate-accordion {
  .accordion-item-category {
    color: $depthDark1;
    text-transform: uppercase;
    @include rem(font-size, 14px);
    @extend .semibold;
  }
}

.vertical-wizard-expander {
  border-radius: 2px;
  box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.1);
}

.vertical-wizard-expander-closed {
  background: $fi-color-highlight-light4;
  border: 1px solid $fi-color-depth-light2;

  .vertical-wizard-expander-content {
    display: none;
  }
}

.vertical-wizard-expander-open {
  background: $fi-color-white-base;
  margin: $fi-spacing-s 0;
}

.vertical-wizard-expander-scroll-offset {
  position: relative;
  top: -70px;
}

.vertical-wizard-expander-content {
  padding: 0 $fi-spacing-m $fi-spacing-m;
}

.vertical-wizard-expander-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $fi-spacing-s $fi-spacing-m;
  background: none;
  border: none;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    background: $fi-color-depth-light2;
  }

  .vertical-wizard-expander-edit {
    color: $fi-color-highlight-base;
    float: right;
  }
}
