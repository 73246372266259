@import "colors";
@import "variables";
@import "buttons";

$header-margin-bottom: convert-length(16px, rem);
$header-row-border-bottom: 1px;
$footer-margin-top: $fi-spacing-xxxxl;

*, *::after, *::before {
  box-sizing: inherit;
}

html, body {
  box-sizing: border-box;
}

b, strong {
  @extend .bold;
}

.link-list a {
  display: inline-block;
  @include rem(padding, .2em 0);
}

* {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

audio, fieldset, form {
  max-width: 100%;
  padding: 0;
}

fieldset {
  min-inline-size: 0;
}

hr {
  margin: 2px 0 18px 0;
  border: none;
  border-top: 1px solid $color-gray-8;
}

/* * * * * * * * * LAYOUT  * * * * * * * */

html {
  background: #fff;
}

body {
  background: $color-body-background;
}

#page-wrapper {
  min-height: 100vh;
  &.full-screen-content {
    display: flex;
    flex-direction: column;

    #main {
      margin-top: calc(-#{$header-margin-bottom} + -#{$header-row-border-bottom});
      flex: 1 0;
      display: flex;
      flex-direction: column;
    }

    #footer {
      display: none;
    }
  }

  &.no-header {
    #page-header,.main-banners {
      display: none;
    }
  }
}

.margin-top-xxl {
  margin-top: $fi-spacing-xxl;
}

.margin-top-l {
  @include rem(margin-top, 30px);
}

.margin-top-m {
  margin-top: $fi-spacing-m
}

.margin-top-s {
  margin-top: $fi-spacing-s;
}

.margin-top-xs {
  margin-top: $fi-spacing-xs
}

.spacing-bottom-l {
  margin-bottom: $fi-spacing-l
}

.margin-bottom-l {
  @include rem(margin-bottom, 30px);
}

.margin-bottom-xs {
  margin-bottom:  $fi-spacing-xs
}

.margin-bottom-s {
  margin-bottom: $fi-spacing-s;
}

.margin-bottom-m {
  margin-bottom: $fi-spacing-m
}

.margin-left-l {
  @include rem(margin-left, 30px);
}

.margin-left-s {
  margin-left: $fi-spacing-s;
}

.margin-right-l {
  @include rem(margin-right, 30px);
}

.margin-right-s {
  margin-right: $fi-spacing-s;
}

.margin-l {
  @include rem(margin, 30px);
}

.margin-vertical-xl {
  @include rem(margin-top, 40px);
  @include rem(margin-bottom, 40px);
}

.margin-vertical-l {
  @include rem(margin-top, 30px);
  @include rem(margin-bottom, 30px);
}

.margin-vertical-s {
  @include rem(margin-top, 15px);
  @include rem(margin-bottom, 15px);
}

.margin-horizontal-l {
  @include rem(margin-left, 30px);
  @include rem(margin-right, 30px);
}

.margin-horizontal-s {
  margin-left: $fi-spacing-s;
  margin-right: $fi-spacing-s;
}

/* Bootstrap helpers */
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after
{
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.padding-0 {
  padding-left: 0;
  padding-right: 0;
}

/* Grid helpers */
@media (min-width: $breakpoint-4) {
  .pull-right-md {
    float: right !important;
  }
  .pull-left-md {
    float: left !important;
  }
}

@media (min-width: $breakpoint-3) {
  .pull-right-sm {
    float: right !important;
  }
  .pull-left-sm {
    float: left !important;
  }
}

/* Bootstrap helpers END */

#main {
  @media (min-width: $breakpoint-6) {
    @include rem(min-height, 100px);
  }
  &:focus {
    outline: 0;
  }
}

#footer {
  margin-top: $footer-margin-top;
}

.container {
  position: relative;
}

.subtopic-navigation-container {
  @media(min-width: $breakpoint-4-1) {
    padding-left:0px;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/****** Common ********/
.text-narrow {
  font-family: SourceSansProLight, SourceSansProRegular, 'Helvetica Neue', Arial;
  font-weight: 400;
}

.semibold {
  font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
  font-weight: 400;
}

.bold {
  font-family: SourceSansProBold, SourceSansProRegular, 'Helvetica Neue', Arial;
  font-weight: 400;

}

.no-margin-loader {
  margin: -20px auto 45px auto;
}

@-webkit-keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em $themecolor2;
  }
  40% {
    box-shadow: 0 2.5em 0 0 $themecolor;
  }
}

@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em $themecolor2;
  }
  40% {
    box-shadow: 0 2.5em 0 0 $themecolor;
  }
}

/* pagination */

// Note: vare-ui uses same pagination styles.
// Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
.organization-list {
  .pagination.pagination {
    margin-bottom: 0;
  }
}

.pagination.pagination {
  margin-bottom: 30px;
  line-height: 1;

  // IE11 needs parent element to be flex as well
  justify-content: center;
  flex-direction: row;
  display: flex;

  li > * {
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
    margin: auto;
    line-height: 36px;
  }

  li.selected + li {
    border-left: 0;
  }

  li {
    border-bottom: 1px solid $gray-60;
    border-left: 1px solid $gray-60;
    border-top: 1px solid $gray-60;

    transition: all 0.2s ease 0s;

    min-height: 38px;
    min-width: 38px;

    color: $lake;
    background-color: #fff;

    &:last-of-type {
      border-right: 1px solid $gray-60;
    }

    a, a:hover, a:visited {
      @include rem(font-size, 16px);
      text-decoration: none;
      outline: 0 none;
      color: $lake;
    }

    a:hover {
      background-color: $lake-ultralight;
    }

    &.disabled {
      a.fi-link {
        pointer-events: none;
        cursor: default;
        color: $gray;
        svg {
          fill: $gray !important;
        }

        &:focus {
          background: #fff
        }
      }
    }

    &.selected {
      border-right: 1px solid;
      border-color: $lake;
      a {
        background-color: $lake;
        @extend .semibold;
        cursor: default;
        color: #fff;
      }
    }
  }

  .pagination-page {
    @extend .text-small;
    @extend .semibold;
    color: $gray-k40;
    min-width: 90px;
    padding: 0 20px;
  }
}

/**********END new tabs navigation ************/

.lg-hidden-down {
  display: none;
}

@media (min-width: $breakpoint-3) {
  .sm-hidden-up {
    display: none;
  }
}

@media (max-width: $breakpoint-3-1) {
  .xs-hidden-down {
    display: none;
  }
}

@media (min-width: $breakpoint-4) {
  .md-hidden-up {
    display: none;
  }
}

@media (max-width: $breakpoint-4-1) {
  .sm-hidden-down {
    display: none;
  }
}

@media (min-width: $breakpoint-6) {
  .lg-hidden-up {
    display: none;
  }
}

@media (max-width: $breakpoint-6-1) {
  .md-hidden-down {
    display: none;
  }
}

.breadcrumb-wrapper {
  @include rem(margin-bottom, 15px);
  @include rem(font-size, 16px);
  @media (max-width: $breakpoint-3-1) {
    @include rem(margin-top, 15px);
  }
  a {
    @include rem(font-size, 16px);
  }

  svg.fi-icon {
    height: 16px;
    width: 16px;
    margin: 0 3px 2px 4px;
    vertical-align: middle;
    color: $depthDark1;
  }
}

.centered {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.centered-desktop {
  @media(min-width: $breakpoint-4) {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: $breakpoint-3-1) {
  .centered-md {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
}

.box-white {
  padding: $fi-spacing-m;
  background: $fi-color-white-base;
  border: 1px solid $color-gray-8;

  > .box-extend-on-padding {
    margin-left: -20px;
    margin-right: -20px;
    &:first-child {
      margin-top: -20px;
    }
    &:last-child {
      margin-bottom: -20px;
    }

    @media(max-width: $breakpoint-3-1) {
      margin-left: -5px;
      margin-right: -5px;
      &:first-child {
        margin-top: -5px;
      }
      &:last-child {
        margin-bottom: -5px;
      }
    }
  }

  &.box-content {
    @media(max-width: $breakpoint-3-1) {
      border-left: none;
      border-right: none;
      border-radius: 0;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

/******* Card *********/

.card {
  @include card;
}

@media (min-width: $breakpoint-3) {
  .card-md-up {
    @include card;
  }
}

/***************/

// moving to use fi-icon, remove when no longer in use
svg.default-icon {
  width: 100%;
  height: auto;
  max-height: 50px;
  max-width: 50px;
  fill: $gray-k40;
}

svg.fi-icon {
  &.sign-language-content-icon {
    width: 100%;
    height: auto;
    max-height: 50px;
    max-width: 50px;
  }
}

// id="id-main-search-button"
.menu-button svg.default-icon {
  max-height: 24px;
  max-width: 24px;
}

#minimal-page-header {
  padding-top: $fi-spacing-m;
  margin-top: -3px;
  .header-row {
    border-top: 4px solid $color-suomi;
  }
}
.main-banners {
  section {
    margin-top: -$header-margin-bottom;
    margin-bottom: $header-margin-bottom;
  }
}

.delegation-banner-container {
  display: block;
  width: 100%;
  background-color: $color-suomi;
  color: $white;
  button.fi-button {
    background-color: $color-suomi;
    background-image: none;
    color: $white;
    border: 1px solid $whiteBase;

    &:hover,&:focus {
      color: $accentSecondary;
    }
  }
  .delegation-banner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: $fi-spacing-xs;
    row-gap: $fi-spacing-xs;

    padding: $fi-spacing-xs $fi-spacing-m $fi-spacing-xs $fi-spacing-m;
    text-align: center;
    @media(max-width: $breakpoint-2-1) {
      justify-content: start;
      text-align: left;
    }

    .fi-text {
      color: $white;
    }
  }
}

.select-role-container {
  .select-role-title {
    @media(max-width: $breakpoint-4-1) {
      h1 {
        text-align: center;
      }
    }
  }

  .select-role-button {
    width: 100%;
    max-width: 350px;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    background: $fi-color-white-base;
    border: none;
    border-left: $highlightBase 5px solid;
    @include border-radius(4px);
    box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);

    .select-role-title {
      width: 150px;
      font-size: 18px;
      font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
      font-weight: 600;
    }
  }

  .select-role-buttons {
    padding-bottom: $fi-spacing-xxxl;
    @media(max-width: $breakpoint-4-1) {
      padding-bottom: $fi-spacing-m;
    }
    border-bottom: $depthLight1 1px solid;
  }

  .select-role-instructions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin-bottom: $fi-spacing-xxl;
    @media(min-width: $breakpoint-2) {
      align-items: center;
    }
  }
}

.myauthorizations-login-hero-info {
  padding-bottom: 60px;
  padding-top: 40px;
  margin-top: 20px;
  position: relative;
}

.myauthorizations-login-hero-image {
  background: url(#{$image_base_path}/images/myauthorizations_login_hero.webp);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media (min-width: $breakpoint-4) {
    height: 400px;
  }
}

.myservices-login-hero-info {
  background-color: #2A6EBB;
  color: white;
  padding-bottom: 25px;
  @media (min-width: $breakpoint-4) {
    padding-top: 40px;
    padding-bottom: 65px;
  }

  .myservices-login-hero-info-icon {
    .icon-white-circle {
      height: 72px;
      width: 72px;
      background-color: white;
      border-radius: 50%;
      padding-top: 7px;
      padding-left: 2px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    svg.fi-icon {
      width: 100%;
      max-width: 54px;
      height: auto;
      max-height: 54px;
    }
  }
  .myservices-login-hero-info-header {
    h2 {
      margin-top: 25px;
      color: white !important;
      margin-right: $margin;
      margin-left: $margin;
    }
    div {
      margin-right: $margin;
      margin-left: $margin;
    }
  }
  .myservices-login-hero-info-button {
    margin-top: 55px;
    .white-focus-highlight:focus {
      box-shadow: 0 0 4px 0 #ffffff;
    }
    @media (max-width: $breakpoint-4-1) {
      margin-top: 25px;
    }
  }
  .myservices-login-hero-info-delegate {
    a {
      color: $white !important;
      text-decoration: underline;
    }
    margin-top: 20px;
  }
}

.myservices-login-content {
  border: none;
  margin: 0;
  padding: 0 20px 20px 20px;
  .myservices-login-content-header {
    margin-top: 30px !important;
  }
  @media (max-width: $breakpoint-4-1) {
    margin-left: -30px;
    margin-right: -30px;
    .myservices-login-content-header {
      margin-top: 20px !important;
    }
  }
  .myservices-login-content-instructions {
    margin-top: 20px;
    @media (max-width: $breakpoint-4-1) {
      margin-top: 10px;
    }
  }
}

.mymessages-login-hero-image {
  background: url(#{$image_base_path}/images/mymessages_login_hero.webp) center center no-repeat;
  background-size: cover;
}

.myregisters-login-hero-image {
  background: url(#{$image_base_path}/images/myregisters_login_hero.webp) center center no-repeat;
  background-size: cover;
}

.myregisters-registerslist-header {
  margin-bottom: 0px;
}

.myregisters-registerslist {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  .register-list-item {
    border-right: none;
    border-left: none;
    &:last-of-type {
      border-bottom: none;
    }
    @media (max-width: $breakpoint-4-1) {
      img {
        max-height: 100px;
        max-width: 240px;
      }
    }
  }
}

.registeries-login {
  background: $themecolor2;
  padding: 20px;
  color: #fff;
  border: 1px solid $themecolor2-dark;
  margin-top: 50px;
  text-align: left;
  @media (max-width: $breakpoint-3) {
    text-align: center;
  }

  .text-light {
    @include rem(font-size, 20px);
    display: inline-block;
    vertical-align: middle;
  }

  .logged-out {
    display: inline-block;
    margin-right: 50px;
    @media (max-width: $breakpoint-3) {
      margin-right: 0;
      padding-bottom: 10px;
      display: block;
    }
  }
}

// is-flex makes bootstrap cols equal heights
.row.is-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.row.is-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.register-login-container {
  background: $lake;
  padding: 20px;

  @media(max-width: $breakpoint-4-1) {
    text-align: center;
  }

  .logged-out {
    display: inline-block;
    margin-right: 50px;
    @media (max-width: $breakpoint-4-1) {
      margin-right: 0;
      padding-bottom: 10px;
      display: block;
    }
  }

  .register-login-description {
    padding-left: 20px;
    color: #fff;
    vertical-align: middle;

    @media(max-width: $breakpoint-4-1) {
      display: block;
      padding-left: 0px;
      padding-top: 10px;
      text-align: center;
    }
  }
}

@media (min-width: $breakpoint-3) {
  .role_front_main_header {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.topic-list {
  background-color: white;
  padding: 20px;
  border: 1px solid $gray-60;

  @media (max-width: $breakpoint-3-1) {
    .topic-list-subtopics {
      margin-left: auto;
      margin-right: auto;
      width: 230px;
      text-align: left;
    }
  }

  .icon {
    @include rem(margin-top, 8px);
    float: right;
    @media (max-width: $breakpoint-3-1) {
      float: none;
    }
  }

  .topic-icon {
    width: 100%;
    height: auto;
    max-height: 50px;
    max-width: 50px;
  }
}

.example-label {
  text-align: center;
  + div {
    opacity: 0.7;
  }
}

.example-label span {
  transform: rotate(-10deg);
  color: $color-chili-crimson;
  display: inline-block;
  padding: 5px 15px;
  border: 3px solid $color-chili-crimson;
  background: #fff;
  @include rem(font-size, 16px);
  @extend .bold;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.main-search {
  h4 {
    @extend .bold;
    text-transform: uppercase;
    @include rem(font-size, 16px);
  }

  .search-results {
    .single-search-result {
      padding: 20px 20px 5px 20px;
      margin: 0 0 18px 0;

      h3 {
        display: inline-block;
        margin-top: 0;

        a {
          margin-right: 10px;
          text-decoration: none;
          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }

      .organization {
        color: $themecolor;
        @include rem(font-size, 16px);
        @include rem(line-height, 16px);
        border-left: 0px solid $color-gray-6;
        padding-left: 0px;
        margin-left: 0px;
        display: inline-block;
        font-family: SourceSansProRegular, ​ Helvetica, ​sans-serif, ​clean;
      }
    }
  }
}

.search-score {
  padding-top: 20px;
}

.search-score-expander {
  position: relative;
  cursor: pointer;
  padding: 5px 0px 5px 0px;
  color: $color-basic-text;
  background: transparent;
  @extend .regular;
  text-align: left;
  text-shadow: none;
  width: 100%;
  font-size: 16px;

}

.search-score-plain {
  position: relative;
  cursor: pointer;
  padding: 5px 0px 5px 0px;
  color: $color-basic-text;
  background: transparent;
  @extend .regular;
  text-align: left;
  text-shadow: none;
  width: 100%;
  font-size: 16px;

}

.search-score-expander-button {
  width: 15px;
  margin-right: 5px;
  font-weight: bolder;
  font-size: 20px;
  color: $lake;
}


.search-score-indent {
  padding: 0px 20px;
  border-left: 1px solid #ced2d5;
}

pre {
  max-width: 100%;
  @include word-wrap;
  overflow: auto;
}

::-moz-selection {
  background: $themecolor;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: $themecolor;
  color: #fff;
  text-shadow: none;
}

video,
img {
  max-width: 100%;
  height: auto;
}

.url {
  @include text-truncate;
  display: block;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
}

/* safari fix */
.flex-box {
  &.container::before,
  &.container::after,
  &.row::before,
  &.row::after {
    content: normal;
  }
}

.service-channels-type-header {
  @include rem(font-size, 18px);
  @include rem(line-height, 20px);
  font-family: SourceSansProRegular, Arial, helvetica, sans-serif, clean;
}

.pill-list-item {
  display: inline-block;
  font-family: SourceSansProRegular, Arial, helvetica, sans-serif, clean;
  font-size: 14px;
  line-height: 18px;
  border-radius: 14px;
  padding: 1px 10px 1px 10px;
  margin-right: 5px;
  margin-top: 5px;
  background: $fi-color-highlight-light3;
  color:  $fi-color-depth-dark1;
  text-transform: none;
}

.search-view-results, .related-services-list, .related-service-channels-list, .organization-list, .mandate-list, .video-list {
  .related-services-list-item, .organization-list-item, .filters-details, .empty-results-list {
    border-top: 1px solid $depthLight1;
    border-right: 1px solid $depthLight1;
    border-left: 1px solid $depthLight1;
    padding: 20px 25px 20px 25px;
    background: white;

    h3 {
      margin-bottom: 5px;
      margin-top: 0;
    }
  }

  .search-results-list, .organization-list-item:last-of-type {
    border-bottom: 1px solid $depthLight1;
    margin-bottom: 30px;
  }

  .related-services-list-item-organizations {
    color: $blackBase;
    @include rem(font-size, 16px);
    @include rem(line-height, 20px);
    @include rem(margin-bottom, 5px);
    font-family: SourceSansProRegular, Arial, helvetica, sans-serif, clean;
  }
  .related-services-list-item-service-types {
    color: $gray-k40;
    @include rem(font-size, 12px);
    @include rem(line-height, 15px);
    font-family: SourceSansProSemibold, Arial, helvetica, sans-serif, clean;
  }
  .organization-list-item-text, .related-services-list-item-text, .search-results-list-item-text {
    font-family: SourceSansProRegular, Arial, helvetica, sans-serif, clean;
    @include rem(font-size, 18px);
    @include rem(line-height, 27px);
    margin-top: 15px;
  }
  .related-services-list-service-channels-header {
    font-family: SourceSansProSemibold, Arial, helvetica, sans-serif, clean;
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
    @include rem(margin-top, 10px);
    @include rem(margin-bottom, 5px);
  }

  .search-result-icon {
    width: 100%;
    height: auto;
    max-height: 50px;
    max-width: 50px;
  }
}

.metadata-list-header {
  font-family: SourceSansProSemibold, Arial, helvetica, sans-serif, clean;
  @include rem(font-size, 16px);
  @include rem(line-height, 18px);
  @include rem(margin-top, 15px);
}

.dropdown-wrapper {
  position: relative;

  .dropdown-content {
    position: absolute;
    z-index: 1000;
  }
  &:not(.active) .dropdown-content {
    display: none;
  }

  & .dropdown-arrow {
    color: $highlightBase;
  }

  &.active .dropdown-arrow {
    transform: rotate(180deg);
  }
}

.subtopic-topic-title {
  font-weight: 600;
}

.service-guide-list-item {
  padding: 1rem 0;
  &:not(:last-child) {
    border-bottom: solid $color-gray-8 1px;
  }
}

.serviceguide-content-module {
  background: $lake-extralight;
  margin-top: 1rem;
  padding: 15px 20px 20px;
  position: relative;
}

@media (max-width: $breakpoint-4-1) {
  .js .related-content-sidebar:not(.open) .related-content-sidebar-content {
    display: none;
  }
}

.listed-content-item-content {
  ul, ol {
    padding-left: 30px;
    margin-bottom: 1.0em;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin-bottom: 0.2em;
  }
}

.check-list {
  background-color: $lake-extralight;
  border: 1px solid $gray;
  padding: 20px;
  margin-bottom: 30px;
  h4 {
    margin-top: 0;
    padding-bottom: 10px;
  }
  ul, ol {
    padding-left: 30px;
    margin-bottom: 1.0em;
  }
  ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    padding-bottom: 10px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.serviceguide-list {
  li {
    list-style-type: none;
  }
}

.metadata-list {
  li {
    list-style-type: none;
    font-size: 14px;
    margin-left: 10px;
  }
}
.metadata-item-list {
  li {
    list-style-type: default;
    font-size: 14px;
    margin-left: 10px;
  }
}

.content-module-sign-language-link-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include rem(line-height, 24px);
  @include rem(margin-bottom, 8px);
  &:last-of-type {
    @include rem(margin-bottom, 0);
  }

  .fi-icon {
    display: inline;
    @include rem(margin-right, 24px);
    @include rem(height, 24px);
    width: auto;
    @include rem(line-height, 24px);
    flex-shrink: 0;
  }
}

.formatted-text {
  ul, ol {
    padding-left: 30px;
    margin-bottom: 1.0em;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin-bottom: 0.2em;
  }
  p {
    max-width: 39em;
  }
}

.page-title {
  margin-bottom: 30px;
}

.responsible-organization {
  margin-top: 1.6rem;
}

.document-title {
  vertical-align: bottom;
  h1 {
    margin-top: 5px;
  }
}

::placeholder {
  color: $color-gray-4;
  font-style: italic;
}
::-webkit-input-placeholder { /* WebKit < 57, Blink */
  color: $color-gray-4;
  font-style: italic;
}
::-moz-placeholder { /* Mozilla Firefox 19-50 */
  color: $color-gray-4;
  font-style: italic;
  opacity:  1;
}

.service-break {
  max-width: 480px;
  .service-break-icon {
    margin-top: 20px;
    svg {
      fill: $red !important;
    }
  }
}

.banner-with-button-blue {
  padding: 15px 35px 19px 20px;
  background: $lake;

  @media(max-width: $breakpoint-3-1) {
    text-align: center;
    padding: 20px;
  }

  .banner-right-col {
    padding-left: 0;
  }

  .banner-left-col {
    padding-right: 0;
  }

  .banner-with-button-row {
    color: #fff;
    flex-direction: row;
    align-items: center;

    span {
      line-height: 1.5;
    }
  }

  .banner-with-button-heading {
    margin-top: 0;
    color: white !important;
    margin-bottom: 5px;
    line-height: 1.31;
  }

  .banner-with-button-buttons {
    display: block;
    float: right;
    > *:not(:first-child) {
      margin-top: 1em;
    }
    @media(max-width: $breakpoint-3-1) {
      margin-top: 1em;
      float: unset;
    }
  }
}

.banner-with-button-white {
  @extend .banner-with-button-blue;
  background: #fff;
  border: 1px solid $color-gray-8;
  .banner-with-button-row {
    color: $color-basic-text;;
  }

  .banner-with-button-heading {
    color: $color-basic-text !important;
  }
}

.error {
  color:#C13832;
}

.rich-popover-title {
  font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial, sans-serif;
  text-transform: uppercase;
  margin-top: 2px;
  margin-bottom: 2px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    padding: 5px;
    &:hover {
      background: #fff !important;
    }
  }

  svg.fi-icon {
    margin-right: 0 !important;
  }
}

.scrollable-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 950;
}

.scroll-shadow {
  background:
          /* Shadow Cover TOP */
          linear-gradient(
                          white 30%,
                          rgba(255, 255, 255, 0)
          ) center top,

            /* Shadow Cover BOTTOM */
          linear-gradient(
                          rgba(255, 255, 255, 0),
                          white 70%
          ) center bottom,

            /* Shadow TOP */
          radial-gradient(
                          farthest-side at 50% 0,
                          rgba(0, 0, 0, 0.2),
                          rgba(0, 0, 0, 0)
          ) center top,

            /* Shadow BOTTOM */
          radial-gradient(
                          farthest-side at 50% 100%,
                          rgba(0, 0, 0, 0.2),
                          rgba(0, 0, 0, 0)
          ) center bottom;

  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.search-filter-modal {
  max-width: 500px;
  padding: 0;

  .search-filter-modal-body {
    background-color: #fff;
    height: 100%;
    padding: 0;
    overflow: auto;
    flex-shrink: 1000;
  }

  .search-filter-modal-body-header {
    padding: 0 20px 0 20px;
    display: flex;

    button {
      margin-left: auto;
    }
  }

  .search-filter-modal-body-content {
    padding: 20px;
  }

  .search-filter-modal-footer {
    background: #fff;
    width: 100%;
    flex-shrink: 0;

    hr {
      margin: 0 0 5px 0;
    }

    .search-filter-modal-footer-results {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      color: $depthDark1;
    }

    .search-filter-modal-footer-button {
      padding: 5px 20px 15px 20px;
    }
  }

  hr {
    margin: 20px 0 20px 0;
  }
}

.embedded-video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    // padding-top will be used to set the wrapper's height but it must be calculated and set with javascript to maintain
    // correct aspect ratio.
}

.embedded-video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.sign-language-video-info {
  @include rem(height, 24px);
  @include rem(line-height, 24px);
  @include rem(margin-bottom, 24px);
  color: $gray-k40;

  svg {
    @include rem(height, 24px);
    @include rem(width, 24px);
    vertical-align: middle;
  }

  svg.fi-icon {
    margin-right: $fi-spacing-xs;
  }

  .sign-language-video-info-text {
    @include rem(margin-left, 5px);
    @include rem(font-size, 16px);
    font-family: SourceSansProSemibold, Arial, helvetica, sans-serif, clean;
    text-transform: uppercase;
  }
}

.sign-language-related-content-link {
  display: flex;
  svg.fi-icon {
    @include rem(height, 24px);
    @include rem(width, 24px);
    vertical-align: middle;
    margin-right: $fi-spacing-xs;
  }
}

.sign-language-related-content-informative-content-link {
  margin-top: -15px;
  margin-bottom: 20px;
}

.sign-language-related-content-informative-content {
  background: $depthSecondary;
  margin-top: 1rem;
  border-top: 4px solid #2E78CC;
  padding: 30px 20px 30px;
  position: relative;
}

.sign-language-filter {
  .toggle-switch-filter-label {
    margin-right: $fi-spacing-xs;
  }

  svg.toggle-switch-filter-icon {
    height: auto;
    max-height: 30px;
    vertical-align: middle;
    @include rem(width, 20px);
  }
}

.sign-language-icon {
  // TODO: Remove when SeviSvg icons no longer in use and using fi-icon instead, which is below.
  svg.default-icon {
    vertical-align: top;
    @include rem(width, 40px);
    @include rem(height, 40px);
    @media (max-width: $breakpoint-3-1) {
      @include rem(width, 25px);
      @include rem(height, 25px);
      @include rem(min-width, 25px);
      @include rem(min-height, 25px);
    }
  }

  svg.fi-icon {
    vertical-align: top;
    @include rem(width, 40px);
    @include rem(height, 40px);
    @media (max-width: $breakpoint-3-1) {
      @include rem(width, 25px);
      @include rem(height, 25px);
      @include rem(min-width, 25px);
      @include rem(min-height, 25px);
    }
  }
}

.arrow-item {
  margin-left: 15px;
  text-align: left;

  a {
    font-size: 18px;
  }
  .fi-icon:not(.fi-link_icon):not(.external-link-icon) {
    margin-bottom: -3px;
    margin-left: -19px;
  }
}

// Pamu considering should this be used globally, but for now all search cards
.related-services-list-item {
  .arrow-list {
    .arrow-item {
      a {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}

.arrow-list {
  .arrow-item {
    a {
      font-size: 16px;
      .fi-link_icon {
        font-size: 14px;
      }
    }
  }
}

.line-with-icon {
  display: flex;
  div:last-child {
    margin-left: auto;
  }
}

.company-wizard-link-list {
  .company-wizard-link-list-title {
    font-size: 16px;
    margin-top: $fi-spacing-s;
    margin-bottom: $fi-spacing-xxs;
    @extend .semibold;
  }
}

.sote-center-title {
  .sote-center-title-organization {
    color: $gray-k20;
    @include rem(font-size, 12px);
  }
  .sote-center-pagetitle {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .sote-center-title-type {
    color: $gray-k20;
    text-transform: uppercase;
    @include rem(font-size, 12px);
  }
}

.sote-center-serviceinfo {
  padding: 10px;
  background-color: #e9f0f8;
  margin-bottom: 1rem;
  h2 {
    margin-bottom: 0px;
  }
}

.siha-content-view {
  $siha-content-view-border: 1px solid $color-gray-8;
  margin: 0 calc(-#{$container-padding-x} * 0.5);

  @media(min-width: $screen-sm-min) {
    margin: 0;
  }

  @media(min-width: $screen-md-min) {
    background: #fff;
    border: $siha-content-view-border;
  }

  .siha-content-view-left {
    padding: 15px;

    @media(min-width: $screen-sm-min) {
      padding: 0;
      margin-bottom: 15px
    }

    @media(min-width: $screen-md-min) {
      padding: 0 15px 0 0;
    }
  }

  .siha-content-view-right {
    background: #fff;
    padding: 15px;
    border-top: $siha-content-view-border;
    border-bottom: $siha-content-view-border;

    @media(min-width: $screen-sm-min) {
      border: $siha-content-view-border;
    }

    @media(min-width: $screen-md-min) {
      border: none;
    }
  }
}

.content-view {
  h1 {
    margin: 0;
  }

  h3 {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .ptv-content-h3 {
    margin-top:15px;
    margin-bottom: 5px;
  }
}

.service-view, .service-channel-view {
  .content-view-mobile {
    .content-view-mobile-related-content {
      background-color: #fff;
    }
  }

  h2 {
    margin-bottom: 20px
  }

  h4 {
    margin-top: 20px;
    margin-bottom: 10px
  }
}

.content-view-mobile {
  $content-view-border: 1px solid $color-gray-8;
  background-color: #fff;
  margin: 0 calc(-#{$container-padding-x} * 0.5);
  border-top: $content-view-border;
  border-bottom: $content-view-border;

  @media (min-width: $screen-sm-min) {
    margin: 0;
    border-left: $content-view-border;
    border-right: $content-view-border;
  }

  .content-view-mobile-header {
    padding: 10px 15px 0 15px;
  }

  .content-view-mobile-related-content {
    background-color: $depthSecondary;

    &.related-section-top {
      margin-top: 25px;
    }

    &:empty {
      display: none;
    }
  }

  .content-view-mobile-content {
    padding: 15px 15px 40px 15px;
  }

  .related-content {
    $related-content-padding-left: 15px;
    $related-content-padding-right: 15px;
    padding: 20px $related-content-padding-right 25px $related-content-padding-left;
    .ptv-related-content-show-all {
      margin-left: -$related-content-padding-left;
      margin-right: -$related-content-padding-right;
      padding: 20px $related-content-padding-right 20px $related-content-padding-left;
    }
  }
}

.content-view-desktop {
  border: 1px solid $color-gray-8;
  .content-view-desktop-left {
    padding: 20px 25px 40px 20px;
    background-color: #fff;
    height: 100%;
  }

  .content-view-desktop-right {
    background-color: $depthSecondary;
    height: 100%;
  }

  .content-view-desktop-content {
    @include rem(margin-top, 24px);
  }

  .related-content {
    $related-content-padding-left: 20px;
    $related-content-padding-right: 20px;
    padding: 35px $related-content-padding-right 25px $related-content-padding-left;
    .ptv-related-content-show-all {
      margin-left: -$related-content-padding-left;
      margin-right: -$related-content-padding-right;
      padding: 20px $related-content-padding-right 20px $related-content-padding-left;
    }
  }
}

.related-content {
  h2 {
    margin-top: 0px;
  }

  &:not(.expanded) .related-content-expander-content {
    display: none
  }

  .related-content-expander-button svg {
    transition: transform 0.3s ease-in-out
  }

  &.expanded .related-content-expander-button svg {
    transform: rotate(-180deg);
  }

  .ptv-related-content-show-all {
    display: block;
    background: $depthSecondaryDark3;
    @include rem(margin-top, 16px);
    .icon {
      @include rem(margin-left, 5px);
      vertical-align: middle;
    }
  }
}

.related-content-expander-button {
  $expander-button-icon-size: 24px;
  margin-bottom: 5px;
  &, &:hover, &:active, &:focus {
    background: none;
  }
  color: $color-basic-text;
  padding: 0;
  @include rem(padding-right, $expander-button-icon-size + 8px);
  position: relative;
  width: 100%;
  text-align: left;
  @include rem(min-height, $expander-button-icon-size);

  .icon {
    @include rem(width, $expander-button-icon-size);
    @include rem(height, $expander-button-icon-size);
    position: absolute;
    right: 0;
    top: 0;
    svg {
      color: $link-color;
      transform: scale(125%);
    }
  }
}

.ptv-service-related-channels-channel-list {
  @include rem(margin-bottom, 16px);
}

.ptv-service-related-channels-show-all-of-type {
  display: block;
  @include rem(margin-top, 16px);
}

.content-type-classification {
  color: $gray-k40;
  @include rem(font-size, 12px);
  @include rem(line-height, 15px);
  @include rem(margin-top, 10px);
  @include rem(margin-bottom, 20px);
  font-family: SourceSansProSemibold, Arial, helvetica, sans-serif, clean;
  text-transform: uppercase;
}

.date-range {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;

  .date-range-column-date {
    width: 200px;
  }
}

.functional-content-highlight {
  background: $arctic-hill-light-30;
  margin-top: 1rem;
  padding: 15px 20px 20px;
  position: relative;
}

.button-bar {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-4-1) {
    flex-direction: column;
  }

  &.button-bar-left {
    justify-content: flex-start;
  }
  &.button-bar-right {
    justify-content: flex-end;
  }

  .button-bar-item {
    margin: 5px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $breakpoint-4-1) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.general-description {
  .info-text-area {
    margin: 30px 0 20px;
  }

  h2, hr {
    margin-top: 30px;
  }

  .law {
    margin-top: 10px;
  }
}

.general-description-expander-button {
  margin: 0;

  h3 {
    margin: 0;
    color: $lake;
  }
}

.info-text-area {
  border-left: 4px solid $accentSecondary;
  background: $accentSecondaryLight1;
  font-size: 16px;
  padding: 20px;
}

.translate-markdown {
  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul, ol {
    margin-bottom: $fi-spacing-l;
    margin-left: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 0;
    }
  }
}

.search-view-skip-link {
  position: relative;
  top: -80px;
  left: -16px;
}

// Workaround to completely hide unfocused skip link from view. suomifi-ui-components only sets left: -1000px which is still on visible area.
.search-view-skip-link:not(:focus-within) {
  overflow: hidden;
}

// For headings and other contexts where link should use parent's font styles instead of those defined by suomifi-ui-components.
.link-inherit-font-style {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
}

.external-link-icon {
  padding-left: 4px;
  box-sizing: content-box;
}

.expander-title-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: $color-bright-azure;
  line-height: 1.5;

  @media(min-width: $breakpoint-2-1) {
    flex-direction: row;
  }

  .expander-title-button-count {
    color: $color-basic-text;
    font-weight: normal;
  }
}

/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

.fi-chip.beta-chip {
  color: $blackBase;
  background: $warning;
}

.document-title.beta {
  h1 {
    margin-bottom: 10px;
  }
  .beta-chip {
    margin-bottom: 20px;
  }
}

.static-error-view {
  max-width: 400px;

  .error-view-failure-icon {
    margin-top: $fi-spacing-m;
    margin-bottom: $fi-spacing-xs;
    height: $fi-spacing-xxxxl;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
