.link-list-aligned-bullet-list {
  list-style: none;
  .link-list-aligned-bullet-list-icon {
    margin-right: 2px;
    svg {
      margin-left: -3px;
      font-size: 16px;
      transform: translateY(0.1em);
    }
  }
}
