
/** Perus fonttityylit **/

@at-root {
	html {
		font-family: SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
		line-height: 1.5;
		color: $color-basic-text;
		-webkit-font-smoothing: antialiased;
		font-kerning: normal;
	}

	body {
		line-height: 1.5;
		@include rem(font-size, 18px)
	}
}

.text {
	max-width: 39em;
}

// Double class selector to override suomifi-ui-component's link styles which has base specificity of 2 classes.
p .fi-link.fi-link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

/** Otsikot **/

.visual-h1,
.visual-h2,
.visual-h3,
.visual-h4,
.heading,
h1,h2,h3,h4,h5,h6{
	font-family: SourceSansProSemibold, SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
	font-weight: 400;
	@include rem(line-height, 1.2em);
	@include rem(margin-bottom, 0.5em);
	@include rem(margin-top, 1.25em);
	color: $color-gray-1;
	font-variant: normal;
	display: block;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-feature-settings: "liga" 0;
	-webkit-font-variant-ligatures: no-common-ligatures;
	font-variant-ligatures: none;
	&.capital-heading {
		text-transform: uppercase;
		line-height: 1;
	}
	overflow-wrap: break-word;
	word-wrap: break-word
}

.visual-h1,
h1 {
	font-family: SourceSansProLight, SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
	@include rem(font-size, 32px);
	@include rem(line-height, 38px);
	@include rem(margin-top, 1em);
	@extend .break-words;
	@media (min-width: $breakpoint-3) {
		@include rem(font-size, 40px);
		@include rem(line-height, 48px);
	}
}

.visual-h2,
h2 {
	@include rem(font-size, 26px);
	@include rem(line-height, 32px);
	@media (min-width: $breakpoint-3) {
		@include rem(font-size, 28px);
		@include rem(line-height, 34px);
	}
}

.visual-h3,
h3 {
	@include rem(font-size, 20px);
	@include rem(line-height, 26px);
	@media (min-width: $breakpoint-3) {
		@include rem(font-size, 22px);
		@include rem(line-height, 28px)
	}
}

.visual-h4,
h4 {
	@include rem(font-size, 16px);
	@include rem(line-height, 20px);
	@media (min-width: $breakpoint-3) {
		@include rem(font-size, 18px);
		@include rem(line-height, 24px)
	}
}

.visual-h5,
.visual-h6,
h6,
h5 {
	@include rem(font-size, 16px);
	@include rem(line-height, 20px);
}

.text-base {
	@include rem(font-size, 18px);
  }

.text-small {
	@include rem(font-size, 16px);
}

.text-smaller {
	@include rem(font-size, 14px);
}

.regular {
	font-family: SourceSansProRegular, "Helvetica Neue", Arial, helvetica, sans-serif, clean;
}

.text-light {
	color: $color-gray-4;
}

.text-depth-dark1 {
	color: $fi-color-depth-dark1
}

p {
	margin: 0.2em 0 0.8em 0;
}

.text-narrow {
  font-family: SourceSansProLight, SourceSansProRegular, 'Helvetica Neue', Arial;
  font-weight: 400;
}

.semibold {
  font-family: SourceSansProSemibold, SourceSansProRegular, 'Helvetica Neue', Arial;
  font-weight: 400;
}

.strong,
.bold {
  font-family: SourceSansProBold, SourceSansProRegular, 'Helvetica Neue', Arial, helvetica, sans-serif, clean;
  font-weight: 400;
}

.italic {
	font-style: italic;
}

.text-centered {
	text-align: center;
}

.bulleted-list,
.numbered-list {
	list-style-position: outside;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 1em;
	ol,
	ul {
		margin-left: 1.5em;
		margin-top: 1em;
		list-style-position: outside;
	}
}

.bulleted-list li,
.numbered-list li {
	margin-bottom: 1em;
}

.bulleted-list{
	list-style-type: disc;
}

.numbered-list{
	list-style-type: decimal;
}

.compact-list {
	margin-top: 0;
	margin-bottom: 0;
	li {
		margin-bottom: 0;
	}
}

.break-words {
	word-break: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

.title-small-text {
	color: $fi-color-depth-dark1;
	@include rem(font-size, 16px);
	@include rem(line-height, 24px);
	font-family: SourceSansPro, Arial, helvetica, sans-serif, clean;
	font-weight: 400;
}