.fi-alert_content, .fi-inline-alert_content {
  // css doesn't have recursive :last-child selector
  > :last-child,
  > :last-child > :last-child,
  > :last-child > :last-child > :last-child {
    margin-bottom: 0 !important;
  }
}

// Set Alerts' content width same as .container max-width.
@each $breakpoint, $min-width in $grid-breakpoints {
  @if map-has-key($container-max-widths, $breakpoint) {
    @media (min-width: $min-width) {
      .fi-alert.fi-alert .fi-alert_style-wrapper {
        max-width: map-get($container-max-widths, $breakpoint);
      }
    }
  }
}

// .fi-modal_overlay z-index is for modals, .fi-portal is for single/multi selects which can be even inside modals
.fi-modal_overlay, .fi-portal {
  z-index: $modal-z-index;
}

// Forcefully unset font-size, font-weight and line-height which are set by suomifi-ui-components.
// All suomifi-ui-components inside the modal should set their own styles anyway and modal applies broken base styles for everything else inside the modal.
.fi-modal_base, .fi-modal_content {
  font-size: unset !important;
  font-weight: unset !important;
  line-height: unset !important;
}
