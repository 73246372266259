$color-dark-gray:					#282828; /* a.k.a. black */
$color-light-gray:					#ececec; /* To be removed? Do not use */
$color-gray-1:						#0f1317; /* To be removed? Do not use */
$color-gray-2:						#2a2e32; /* To be removed? Do not use */
$color-gray-3:						#464a4d; /* To be removed? Do not use */
$color-gray-4:						#616568; /* To be removed? Do not use */
$color-gray-5:						#7d8184; /* To be removed? Do not use */
$color-gray-6:						#a5acb0; /* To be removed? Do not use */
$color-gray-7:						#b3b7ba; /* To be removed? Do not use */
$color-gray-8:						#ced2d5; /* To be removed? Do not use */
$color-gray-9:						#e9edf0; /* To be removed? Do not use */
$color-gray-10:						#f2f4f8; /* To be removed? Do not use */

$color-citizen:						#003479; /* To be removed, do not use */
	$color-citizen-darkest:			#002454; /* To be removed, do not use */
	$color-citizen-darker:			#082c5c; /* To be removed, do not use */
	$color-citizen-dark:			#0a346b; /* To be removed, do not use */
	$color-citizen-light:			#335d94; /* To be removed, do not use */
	$color-citizen-lighter:			#6685af; /* To be removed, do not use */
	$color-citizen-lightest:		#99aec9; /* To be removed, do not use */

$color-bright-azure:				#2a6ebb; /* To be removed, do not use */
	$color-bright-azure-darkest:	#1d4d83; /* To be removed, do not use */
	$color-bright-azure-darker:		#25558a; /* To be removed, do not use */
	$color-bright-azure-dark:		#2c62a0; /* To be removed, do not use */
	$color-bright-azure-light:		#558bc9; /* To be removed, do not use */
	$color-bright-azure-lighter:	#7fa8d6; /* To be removed, do not use */
	$color-bright-azure-lightest:	#aac5e4; /* To be removed, do not use */

$color-persimmon:					#ea7125; /* To be removed, do not use */

$color-deep-orchid-dark:		#89579b; /* To be removed, do not use */

$color-chili-crimson:				#c13832; /* To be removed, do not use */

/* Change themecolors */

$themecolor: $color-citizen;
	$themecolor-darkest:	$color-citizen-darkest;
	$themecolor-darker:		$color-citizen-darker;
	$themecolor-dark:		$color-citizen-dark;
	$themecolor-light:		$color-citizen-light;
	$themecolor-lighter:	$color-citizen-lighter;
	$themecolor-lightest:	$color-citizen-lightest;

$themecolor2: $color-bright-azure;
	$themecolor2-darkest:	$color-bright-azure-darkest;
	$themecolor2-darker:	$color-bright-azure-darker;
	$themecolor2-dark:		$color-bright-azure-dark;
	$themecolor2-light:		$color-bright-azure-light;
	$themecolor2-lighter:	$color-bright-azure-lighter;
	$themecolor2-lightest:	$color-bright-azure-lightest;

/***************/

$color-suomi:						#003479;
	$suomi-darkest:					#01193c;
	$suomi-darker:					#002454;
	$suomi-dark:					#002e6c;
	$suomi-light:					#1a4886;
	$suomi-lighter:					#4d71a1;
	$suomi-lightest:				#8199bc;

$lake:								#2A6EBB;
	$lake-darkest:					#14365d;
	$lake-darker:					#1d4d83;
	$lake-dark:						#2562a7;
	$lake-light:					#3f7cc1;
	$lake-lighter:					#6a99cf;
	$lake-lightest:					#94b6dd;
	$lake-extralight:				#e9f0f8;
  $lake-ultralight:               #eef5ff;
  $lake-grey-dark:          #a5acb0;
  $lake-grey-light:         #f6f6f7;

$darkness:							#002e5f;

$sky:								#34b6e4;

$cloudberry:						#ea7125;

$gray:							#A5ACB0;
$gray-60:						#C9CDCF;
$gray-20:						#EDEEEF;
$gray-10:						#F6F6F7;
$gray-5:						#FAFAFA;
$gray-k20:						#84898C;
$gray-k40:						#636769;

$arctic-hill-light-26: #ECEDEE;
$arctic-hill-light-30: #F7F7F8;

/* Traffic lights */

$green:							#00B38A;
	/* green tones? */
$yellow:						#F4AA00;
	/* yellow tones? */
$red:							#C13832;
	/* red tones? */

$depthSecondary: #F0F6FF;
$depthSecondaryDark3: #E0EDFF;
$depthBase: #A5ADB1;
$depthDark1: #5F686D;
$depthDark2: #6D787E;
$depthDark3: #8C969B;
$depthLight1: #C8CDD0;
$depthLight2: #ECEDEE;
$depthLight3: #F7F7F8;
$highlightLight4: #F7FAFD;
$blackBase: #212121;
$whiteBase: #FFFFFF;
$digiDeveloper: #60cdcb;
$highlightBase: #2A6EBB;

$accentSecondary: #1a99c7;
$accentSecondaryLight1: #f1fafd;

$color-basic-text: $blackBase;
$link-color: $highlightBase;
$color-body-background: #fafafa;

$kehittajilleTurkoosi: #60CDCB;
