.highlight-box {
	text-align: left;
	background: #fff;
	border: 1px solid $gray-60;
	border-top: none;
	padding: 20px 20px 40px;
	hyphens: none;
	-webkit-hyphens: none;
	display: flex;
	flex-direction: column;

	h2 {
		margin-top: 0;
	}

	hr {
		margin-bottom: 20px;
		margin-top: 20px;
	}
}
