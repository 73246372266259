@mixin fonts {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

#main blockquote {
  @include fonts;

  color: $blackBase;
  border-left: 5px solid $depthDark2;
  padding: 15px 30px;
  margin-bottom: 30px;
  margin-top: 30px;

  a {
    @include fonts;
  }

  .fi-text--bold {
    font-size: inherit;
  }
}
