@font-face {
  font-family: 'SourceSansProBold';
  src: url('#{$font_base_path}/font/SourceSansProBold.woff2') format('woff2'),
  url('#{$font_base_path}/font/SourceSansProBold.woff') format('woff'),
  url('#{$font_base_path}/font/SourceSansProBold.ttf') format('truetype'),
  url('#{$font_base_path}/font/SourceSansProBold.svg#SourceSansProBold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansProSemibold';
  src: url('#{$font_base_path}/font/SourceSansProSemibold.woff2') format('woff2'),
  url('#{$font_base_path}/font/SourceSansProSemibold.woff') format('woff'),
  url('#{$font_base_path}/font/SourceSansProSemibold.ttf') format('truetype'),
  url('#{$font_base_path}/font/SourceSansProSemibold.svg#SourceSansProSemibold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansProRegular';
  src: url('#{$font_base_path}/font/SourceSansProRegular.woff2') format('woff2'),
  url('#{$font_base_path}/font/SourceSansProRegular.woff') format('woff'),
  url('#{$font_base_path}/font/SourceSansProRegular.ttf') format('truetype'),
  url('#{$font_base_path}/font/SourceSansProRegular.svg#SourceSansProRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$font_base_path}/font/SourceSansProLight.woff2') format('woff2'),
  url('#{$font_base_path}/font/SourceSansProLight.woff') format('woff'),
  url('#{$font_base_path}/font/SourceSansProLight.ttf') format('truetype'),
  url('#{$font_base_path}/font/SourceSansProLight.svg#SourceSansProLight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$font_base_path}/font/SourceSansProRegular.woff2') format('woff2'),
  url('#{$font_base_path}/font/SourceSansProRegular.woff') format('woff'),
  url('#{$font_base_path}/font/SourceSansProRegular.ttf') format('truetype'),
  url('#{$font_base_path}/font/SourceSansProRegular.svg#SourceSansProRegular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('#{$font_base_path}/font/SourceSansProSemibold.woff2') format('woff2'),
  url('#{$font_base_path}/font/SourceSansProSemibold.woff') format('woff'),
  url('#{$font_base_path}/font/SourceSansProSemibold.ttf') format('truetype'),
  url('#{$font_base_path}/font/SourceSansProSemibold.svg#SourceSansProSemibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSansProLight';
  src: url('#{$font_base_path}/font/SourceSansProLight.woff2') format('woff2'),
  url('#{$font_base_path}/font/SourceSansProLight.woff') format('woff'),
  url('#{$font_base_path}/font/SourceSansProLight.ttf') format('truetype'),
  url('#{$font_base_path}/font/SourceSansProLight.svg#SourceSansProLight') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceCodeProRegular';
  src: url('#{$font_base_path}/font/SourceCodeProRegular.woff2') format('woff2'),
       url('#{$font_base_path}/font/SourceCodeProRegular.woff') format('woff'),
       url('#{$font_base_path}/font/SourceCodeProRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: SourceSansProRegular, 'Helvetica Neue', Arial;
  -webkit-font-smoothing: antialiased;
}
