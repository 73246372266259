.fi-side-navigation.fi-side-navigation {
  .fi-side-navigation_list {
    padding-left: 10px;
  }

  .fi-side-navigation-item--level-1 > span > .fi-link--router {
    text-transform: none;
  }

  .fi-side-navigation-item--level-2:not(.fi-side-navigation-item--selected) > span > .fi-link--router {
    &:not(:hover) {
      background: none;
    }
  }

  .fi-side-navigation-item_content-wrapper {
    svg.fi-icon {
      padding: 0;
      margin: 0 10px;
    }
  }
}
