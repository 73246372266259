.spotlight {
  $spotlight-border-radius: 4px;
  text-align: left;
  border-radius: $spotlight-border-radius;
  box-shadow: 0 2px 4px 1px rgba(41, 41, 41, 0.2);
  height: 100%;

  a.fi-link {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      text-decoration: none;
      .spotlight-link-text {
        text-decoration: underline;
      }
    }
  }

  .spotlight-image {
    position: relative;
  }

  img {
    display: block;
    border-radius: $spotlight-border-radius $spotlight-border-radius 0 0;
    width: 100%;
  }

  .spotlight-image-text {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: $fi-color-accent-tertiary-dark1;
    border-radius: 14px;
    color: #fff;
    height: 28px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;

    &.sihard-DIHARD {
      color: $digiDeveloper;
      background: $blackBase;
    }
  }

  .spotlight-item-text {
    background: #fff;
    padding: 10px 15px 20px;
    border-radius: 0 0 $spotlight-border-radius $spotlight-border-radius;
    flex-grow: 1;

    .arrow-item {
      margin-top: 10px;
    }
  }
}
