.autosuggest-container {
  position: relative;
  .autosuggest-input:focus {
    outline: none;
  }
  .autosuggest-container--open .autosuggest-input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .autosuggest-suggestions-container {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: #fff;
    font-family: inherit;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 8;
  }
  .autosuggest-suggestion {
    cursor: pointer;
    padding: 10px 20px;
    text-align: left;
    color: $color-basic-text;
  }
  .autosuggest-suggestion--focused {
    background-color: #ddd;
  }
  .autosuggest-input {
    border: 1px solid $gray;
    border-radius: 2px;
    color: #000;
    height: 40px;
    padding: 5px 114px 5px 10px;

    @media (max-width: $breakpoint-4-1) {
      border: 1px solid $lake; //???
      @include rem(font-size, 16px);
      font-style: normal !important;
      letter-spacing: 0;
      line-height: 20px;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}

.autosuggest-container--open {
  .autosuggest-suggestions-container {
    ul {
      border: 1px solid $color-gray-5;
      border-top: 0px;
      border-radius: 2px;
      li span {
        @include rem(line-height, 24px);
      }
    }
  }
}
